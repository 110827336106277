import axios from "axios";

const categoryOrder = ["Speaking", "Writing", "Reading", "Listening"];
const subCategoryOrder = {
  Speaking: ["Read Aloud", "Repeat Sentence", "Describe Image", "Re-tell Lecture", "Respond to a situation", "Answer Short Question"],
  Writing: ["Summarize Written Text", "Write Essay", "Write Email"],
  Reading: ["Reading & Writing: Fill in the Blanks", "Multiple Choice, Multiple Answers", "Re-order Paragraphs", "Reading: Fill in the Blanks", "Multiple Choice, Single Answer"],
  Listening: ["Summarize Spoken Text", "Listening: Multiple Choice, Multiple Answers", "Fill in the Blanks", "Highlight Correct Summary", "Listening: Multiple Choice, Single Answer", "Select Missing Word", "Highlight Incorrect Words", "Write from Dictation"],
};

// Helper function to sort responses
const sortResponses = (responses, categoryOrder, subCategoryOrder) => {
  return responses.sort((a, b) => {
    // Handle missing Category or SubCategory
    const categoryA = a.Category || "";
    const categoryB = b.Category || "";
    const subCategoryA = a.SubCategory || "";
    const subCategoryB = b.SubCategory || "";

    // Determine the order index for categories
    const categoryIndexA = categoryOrder.indexOf(categoryA);
    const categoryIndexB = categoryOrder.indexOf(categoryB);

    // Categories not in categoryOrder are placed at the end
    const finalCategoryIndexA = categoryIndexA !== -1 ? categoryIndexA : categoryOrder.length;
    const finalCategoryIndexB = categoryIndexB !== -1 ? categoryIndexB : categoryOrder.length;

    if (finalCategoryIndexA < finalCategoryIndexB) return -1;
    if (finalCategoryIndexA > finalCategoryIndexB) return 1;

    // If categories are the same, sort by subcategory
    if (categoryA === categoryB) {
      const subOrder = subCategoryOrder[categoryA] || [];
      const subIndexA = subOrder.indexOf(subCategoryA);
      const subIndexB = subOrder.indexOf(subCategoryB);

      const finalSubIndexA = subIndexA !== -1 ? subIndexA : subOrder.length;
      const finalSubIndexB = subIndexB !== -1 ? subIndexB : subOrder.length;

      if (finalSubIndexA < finalSubIndexB) return -1;
      if (finalSubIndexA > finalSubIndexB) return 1;
    }

    return 0;
  });
};


export const fetchMockTestScore = async ({
  url,
  setIsLoading,
  setMockTestsResult,
  splitCategories,
  setMockTestType,
  withCredentials = true
}) => {
  setIsLoading(true); 

  try {
    const response = await axios.get(url, { withCredentials: withCredentials });

    if (response.status === 200 && response.data.message === "Mock Test Score:") {
      const parsedResponses = response.data.response.map((item) => {
        let parsedUserResponse = null;
        if (item.UserResponse) {
          try {
            const firstParse = JSON.parse(item.UserResponse);
            parsedUserResponse =
              typeof firstParse === "string" ? JSON.parse(firstParse) : firstParse;
          } catch (parseError) {
            console.error("Error parsing UserResponse:", parseError);
            parsedUserResponse = null;
          }
        }
        return {
          ...item,
          Category: parsedUserResponse?.Category || item.Category || "",
          SubCategory: parsedUserResponse?.SubCategory || item.SubCategory || "",
          UserResponse: parsedUserResponse,
        };
      });

      const hasScore = parsedResponses.some((item) => item.UserResponse !== null);

      if (hasScore) {
        const sortedResponses = sortResponses(parsedResponses, categoryOrder, subCategoryOrder);
        setMockTestType(sortedResponses[0]?.UserResponse?.mockTestType || "");
        setMockTestsResult(sortedResponses);
        splitCategories(sortedResponses);
        return { success: true, message: "Score fetched successfully", data: sortedResponses };
      } else {
        return { success: false, message: "No score available" };
      }
    } else if (response.status === 300) {
      return { success: false, message: response.data.message || "No Mock Test Score available" };
    } else {
      return { success: false, message: "Unexpected response from the server." };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false, message: "Failed to fetch mock test scores." };
  } finally {
    setIsLoading(false);
  }
};