import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, useMediaQuery } from "@mui/material";
import RecordingComponent from "./RecordingComponent";
import Filler from "../../../assets/audios/filler_audio.mp3";
import { PurpleBtn } from "../../Common/Style";
import {
  AnswerShortQuestion,
  DescribeImage,
  EndTest,
  GetMocktestQuestion,
  MakeQuestionAttempted,
  ReadAloud,
  RepeatSentence,
  RetellLecture,
} from "../../../Client/request";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/joy";
import SaveExitBtn from "../SaveExitBtn";


const guideline = {
  "Read Aloud":
    "Look at the text below. In 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.",
  "Repeat Sentence":
    "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.",
  "Describe Image":
    "Look at the image below. In 25 seconds, please speak into the microphone and describe in detail what the image is showing. You will have 40 seconds to give your response.",
  "Re-tell Lecture":
    "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response. You may also see an image sometimes.",
  "Answer Short Question":
    "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.",
};

export default function SpeakingSectional({
  ExamId,
  quest,
  step,
  setStep,
  MockTestAttemptID,
  totalQuestions,
  commulative,
  setCommulative,
  stopRecordingProcess,
  questionsTime,
  startStopwatch,
  stopStopwatch,
  resetQuestionTimer,
}) {
  const [transcript, setTranscript] = useState("");
  const audioRef = useRef(null);
  const isLaptopTwo = useMediaQuery("(max-width:1000px)");
  const isLaptopOne = useMediaQuery("(max-width:1400px)");
  const [Loading, setLoading] = useState(true);
  const [AudioUrl, setAudioUrl] = useState();
  const [ImageLoading, setImageLoading] = useState(true);
  const [quest_data, setQuest_data] = useState();
  const [DisabledButton, setDisabledButton] = useState(true);
  const mediaRecorderRef = useRef(null);
  const [clicked, setclicked] = useState(false);
  const [tempQuest, setTempQuest] = useState(quest);
  const [tempQuestData, setTempQuestData] = useState(quest_data);
  const [tempTranscript, setTempTranscript] = useState("");
  const [transcriptLoading, setTranscriptLoading] = useState(false);
  const [NetworkError, setNetworkError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setQuest_data(null);
  }, []);

  function formatText(text) {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  const handleExit = () => {
    if (audioRef.current) {
      audioRef.current.pause(); 
    }
    navigate("/MockTest");
  };

  const handleNext = () => {
    if (DisabledButton) return;
    if (audioRef.current) {audioRef.current.pause()}
    setTempQuest(quest);
    setTempQuestData(quest_data);
    if (transcript !== "") {setTempTranscript(transcript)}
    setDisabledButton(true);
    stopStopwatch();
    setclicked(!clicked);
  };

  useEffect(() => {
    if (quest) {
      setLoading(true);
      GetMocktestQuestion(quest.QuestionId).then((res) => {
        if (res.data) {
          setQuest_data(res.data);
          setLoading(false);
          setTranscript("");
          startStopwatch();
        }
      });
    }
  }, [quest]);

  useEffect(() => {
    if (AudioUrl && AudioUrl.size > 0 && !transcriptLoading) {
      if (step !== totalQuestions) {
        setStep(step + 1);
      }
      setLoading(true);

      const timeSpent = questionsTime / 1000;
      setCommulative(commulative + timeSpent);

      if (tempQuest.SubCategory === "Read Aloud") {
        let AI_payload = {
          audiofile: AudioUrl,
          script: tempQuestData.QuestionStatement,
          user_response: tempTranscript,
        };

        ReadAloud(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript ? tempTranscript : "",
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: timeSpent,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + timeSpent,
              }),
              is_ptecore: false,
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              if (!quest.IsAttempted) {
                quest.IsAttempted = true;
              }
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    navigate("/MockTest");
                  }
                });
              }
            });
          }
        });
      } else if (tempQuest.SubCategory === "Repeat Sentence") {
        let AI_payload = {
          audiofile: AudioUrl,
          correct_text: tempQuestData.QuestionStatement,
          user_text: tempTranscript,
        };
        RepeatSentence(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript ? tempTranscript : "",
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: timeSpent,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + timeSpent,
              }),
              is_ptecore: false,
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              if (!quest.IsAttempted) {
                quest.IsAttempted = true;
              }
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    navigate("/MockTest");
                  }
                });
              }
            });
          }
        });
      } else if (tempQuest.SubCategory === "Describe Image") {
        let AI_payload = {
          audiofile: AudioUrl,
          user_text: tempTranscript,
          major_aspects: JSON.stringify(tempQuestData.MajorAspects.flat()),
          minor_aspects: JSON.stringify(tempQuestData.MinorAspects.flat()),
        };
        DescribeImage(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript ? tempTranscript : "",
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: timeSpent,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + timeSpent,
              }),
              is_ptecore: false,
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              if (!quest.IsAttempted) {
                quest.IsAttempted = true;
              }
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    navigate("/MockTest");
                  }
                });
              }
            });
          }
        });
      } else if (tempQuest.SubCategory === "Re-tell Lecture") {
        let AI_payload = {
          audiofile: AudioUrl,
          script: tempQuestData.QuestionStatement,
          user_response: tempTranscript,
        };
        RetellLecture(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript,
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: timeSpent,
              is_ptecore: false,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + timeSpent,
              }),
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              if (!quest.IsAttempted) {
                quest.IsAttempted = true;
              }
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    navigate("/MockTest");
                  }
                });
              }
            });
          }
        });
      } else if (tempQuest.SubCategory === "Answer Short Question") {
        let AI_payload = {
          question: tempQuestData.QuestionStatement,
          user_answer: tempTranscript,
          answer_list: tempQuestData.OptionNames,
        };
        AnswerShortQuestion(AI_payload).then((res) => {
          if (res.data) {
            let response = {
              QuestionId: tempQuest.QuestionId,
              QuestionStatement: tempQuest.QuestionStatement,
              QuestionName: tempQuest.QuestionName,
              Category: tempQuest.Category,
              SubCategory: tempQuest.SubCategory,
              UserResponse: tempTranscript ? tempTranscript : "",
              AI_response: JSON.stringify(res.data),
            };
            let payload = {
              mock_test_question_id: tempQuest.MockTestQuestionTableId,
              marks_obtained: 100,
              user_response: JSON.stringify(response),
              time_taken: timeSpent,
              is_ptecore: false,
              all_times: JSON.stringify({
                Category: quest.Category,
                commulativeTime: commulative + timeSpent,
              }),
              mock_test_attempt_id: MockTestAttemptID
                ? MockTestAttemptID
                : ExamId,
            };
            MakeQuestionAttempted(payload).then((res) => {
              if (!quest.IsAttempted) {
                quest.IsAttempted = true;
              }
              setAudioUrl(null);
              if (res.error) {
                setNetworkError(true);
                setTimeout(() => {
                  navigate("/Mocktest");
                }, 2000);
              }
              if (step === totalQuestions) {
                const endPayload = {
                  mock_test_attempt_id: MockTestAttemptID
                    ? MockTestAttemptID
                    : ExamId,
                  end_time: new Date().toLocaleDateString(),
                };
                EndTest(endPayload).then((res) => {
                  if (res) {
                    // console.log("res", res);
                    navigate("/MockTest"); ///mt-score
                  }
                });
              }
            });
          }
        });
      }

      resetQuestionTimer();
      setQuest_data(null);

    }
  }, [AudioUrl, transcriptLoading]);

  return (
    <div style={{}}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={NetworkError}
        variant={"outlined"}
        color={"danger"}
        onClose={() => {
          setNetworkError(false);
        }}
      >
        Network Error
      </Snackbar>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: isLaptopTwo ? "1rem 5% 2rem" : "3rem 5% 2rem",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            // height: "400px",
            width: "100%",
            borderRadius: "24px",
            position: "relative",
            overflow: "clip",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#e2e2ea",
              height: "13px",
              position: "absolute",
            }}
          ></div>
          <div
            style={{
              width: `${(step / totalQuestions) * 100}%`,
              backgroundColor: "#996cfe",
              height: "13px",
              position: "absolute",
            }}
          ></div>
          {Loading ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                width: "full",
                minHeight: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                //   width: "100%",
                height: "100%",
                padding: "20px",
                marginTop: "20px",
              }}
            >
              <h1
                style={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "32px",
                  userSelect: "none",
                }}
                onCopy={(e) => e.preventDefault()}
              >
                {guideline[quest.SubCategory]}
              </h1>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: isLaptopOne ? "column" : "row",
                }}
              >
                {quest.SubCategory === "Describe Image" && (
                  <div
                    style={{
                      minWidth: isLaptopOne ? "100px" : "627px",
                      minHeight: isLaptopOne ? "100px" : "536px",
                      maxWidth: "800px",
                      borderRadius: "8px",
                      backgroundColor: "rgba(153, 108, 254, 0.10)",
                      overflow: "hidden", 
                      display: "flex", 
                      alignItems: "center",
                      justifyContent: "center", 
                    }}
                  >
                    {ImageLoading && <CircularProgress />}
                    <img
                      src={quest_data.QuestionImage}
                      alt="img"
                      onLoad={() => {
                        setImageLoading(false);
                      }}
                      style={{
                        maxWidth: "80%", 
                        maxHeight: "90%",
                        display: ImageLoading ? "hidden" : "block",
                      }}
                    />
                  </div>
                )}

                {quest_data.QuestionImage && quest.SubCategory === "Re-tell Lecture" && (
                  <div
                    style={{
                      minWidth: isLaptopOne ? "100px" : "627px",
                      minHeight: isLaptopOne ? "100px" : "536px",
                      maxWidth: "800px",
                      borderRadius: "8px",
                      backgroundColor: "rgba(153, 108, 254, 0.10)",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center", 
                      justifyContent: "center", 
                    }}
                  >
                    {ImageLoading && <CircularProgress />}
                    <img
                      src={quest_data.QuestionImage}
                      alt="img"
                      onLoad={() => {
                        setImageLoading(false);
                      }}
                      style={{
                        maxWidth: "80%", 
                        maxHeight: "90%",
                        display: ImageLoading ? "hidden" : "block",
                      }}
                    />
                  </div>
                )}
                <div style={{ width: isLaptopOne ? "100%" : "40%" }}>
                  {quest_data && (
                    <RecordingComponent
                      callNext={() => handleNext()}
                      setTempTranscript={setTempTranscript}
                      clicked={clicked}
                      step={step}
                      transcript={transcript}
                      setAudioUrl={setAudioUrl}
                      setTranscript={setTranscript}

                      Beginning={
                        quest.SubCategory === "Read Aloud"
                        ? 0
                        : quest.SubCategory === "Answer Short Question"
                        ? 3
                        : quest.SubCategory === "Describe Image"
                        ? 0
                        : quest.SubCategory === "Re-tell Lecture"
                        ? 3
                        : quest.SubCategory === "Respond to a situation"
                        ? 3
                        : quest.SubCategory === "Repeat Sentence"
                        ? 3
                        : 0
                      }
                      Waiting={
                        quest.SubCategory === "Read Aloud"
                        ? 35
                        : quest.SubCategory === "Answer Short Question"
                        ? 0
                        : quest.SubCategory === "Describe Image"
                        ? 25
                        : quest.SubCategory === "Re-tell Lecture"
                        ? 10
                        : quest.SubCategory === "Respond to a situation"
                        ? 20
                        : quest.SubCategory === "Repeat Sentence"
                        ? 0
                        : 0
                      }
                      RecordingTime={
                        quest.SubCategory === "Read Aloud"
                        ? 40
                        : quest.SubCategory === "Answer Short Question"
                        ? 7
                        : quest.SubCategory === "Re-tell Lecture"
                        ? 40
                        : quest.SubCategory === "Repeat Sentence"
                        ? 15
                        : quest.SubCategory === "Respond to a situation"
                        ? 15
                        : quest.SubCategory === "Describe Image"
                        ? 40
                        : 0
                      }

                      // Beginning={
                      //   //Time Before Starting The Audio
                      //   quest.SubCategory === "Answer Short Question"
                      //     ? 3
                      //     : quest.SubCategory === "Describe Image"
                      //     ? 0
                      //     : quest.SubCategory === "Re-tell Lecture"
                      //     ? 3
                      //     : quest.SubCategory === "Repeat Sentence"
                      //     ? 3
                      //     : quest.SubCategory === "Respond to a situation"
                      //     ? 3
                      //     : 0
                      // }
                      // Waiting={
                      //   quest.SubCategory === "Answer Short Question"
                      //     ? 0
                      //     : quest.SubCategory === "Describe Image"
                      //     ? 5
                      //     : quest.SubCategory === "Re-tell Lecture"
                      //     ? 0
                      //     : quest.SubCategory === "Repeat Sentence"
                      //     ? 0
                      //     : quest.SubCategory === "Respond to a situation"
                      //     ? 0
                      //     : 0
                      // }
                      // RecordingTime={
                      //   //Time of Recording The User Response
                      //   quest.SubCategory === "Answer Short Question"
                      //     ? 7
                      //     : quest.SubCategory === "Re-tell Lecture"
                      //     ? 40
                      //     : quest.SubCategory === "Repeat Sentence"
                      //     ? 15
                      //     : quest.SubCategory === "Describe Image"
                      //     ? 40
                      //     : quest.SubCategory === "Respond to a situation"
                      //     ? 15
                      //     : 40
                      // }

                      audio={
                        quest.SubCategory === "Describe Image" ||
                        quest.SubCategory === "Read Aloud"
                          ? null
                          : quest.SubCategory === "Repeat Sentence" ||
                            quest.SubCategory === "Answer Short Question" ||
                            quest.SubCategory === "Re-tell Lecture" ||
                            quest.SubCategory === "Respond to a situation"
                          ? quest_data.AudioObjects[0].audio_url ||
                            quest_data.AudioObjects[0].url
                          : Filler
                      }
                      MicAudioRef={audioRef}
                      setDisabledButton={setDisabledButton}
                      mediaRecorderRef={mediaRecorderRef}
                      setTranscriptLoading={setTranscriptLoading}
                      stopRecordingProcess={stopRecordingProcess}
                    />
                  )}
                </div>
              </div>
              {quest.SubCategory === "Read Aloud" && (
                <h5
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    userSelect: "none",
                  }}
                  onCopy={(e) => e.preventDefault()}
                >
                  {" "}
                  {formatText(quest_data.QuestionStatement)}
                </h5>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "45px",
                }}
              >
                <SaveExitBtn handleExit={handleExit} />
                <PurpleBtn onClick={handleNext}
                 disabled={DisabledButton}
                 >
                  Next
                </PurpleBtn>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


// import React, { useEffect, useRef, useState } from "react";
// import { CircularProgress, useMediaQuery } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { Snackbar } from "@mui/joy";
// import RecordingComponent from "./RecordingComponent";
// import Filler from "../../../assets/audios/filler_audio.mp3";
// import { PurpleBtn } from "../../Common/Style";
// import SaveExitBtn from "../SaveExitBtn";

// import {
//   AnswerShortQuestion,
//   DescribeImage,
//   EndTest,
//   GetMocktestQuestion,
//   MakeQuestionAttempted,
//   ReadAloud,
//   RepeatSentence,
//   RetellLecture,
// } from "../../../Client/request";

// const guideline = {
//   "Read Aloud": "Look at the text below. In 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.",
//   "Repeat Sentence": "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.",
//   "Describe Image": "Look at the image below. In 25 seconds, please speak into the microphone and describe in detail what the image is showing. You will have 40 seconds to give your response.",
//   "Re-tell Lecture": "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response. You may also see an image sometimes.",
//   "Answer Short Question": "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.",
// };

// export default function SpeakingSectional({
//   ExamId,
//   quest,
//   step,
//   setStep,
//   MockTestAttemptID,
//   totalQuestions,
//   commulative,
//   setCommulative,
//   stopRecordingProcess,
//   questionsTime,
//   startStopwatch,
//   stopStopwatch,
//   resetQuestionTimer,
// }) {
//   const [Loading, setLoading] = useState(true);
//   const [ImageLoading, setImageLoading] = useState(true);
//   const [questData, setQuestData] = useState(null);
//   const [AudioUrl, setAudioUrl] = useState(null);
//   const [transcript, setTranscript] = useState("");
//   const [tempTranscript, setTempTranscript] = useState("");
//   const [DisabledButton, setDisabledButton] = useState(true);
//   const [transcriptLoading, setTranscriptLoading] = useState(false);
//   const [NetworkError, setNetworkError] = useState(false);
//   const [clicked, setClicked] = useState(false);

//   const audioRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const navigate = useNavigate();

//   const isLaptopTwo = useMediaQuery("(max-width:1000px)");
//   const isLaptopOne = useMediaQuery("(max-width:1400px)");

//   // Fetch question data whenever the question changes
//   useEffect(() => {
//     if (quest) {
//       setLoading(true);
//       setTranscript("");
//       GetMocktestQuestion(quest.QuestionId).then((res) => {
//         if (res.data) {
//           setQuestData(res.data);
//           setLoading(false);
//           startStopwatch();
//         }
//       });
//     }
//   }, [quest]);

//   // Handle submission logic when AudioUrl is set
//   useEffect(() => {
//     if (AudioUrl && AudioUrl.size > 0 && !transcriptLoading) {
//       const timeSpent = questionsTime / 1000;
//       const updatedCommulative = commulative + timeSpent;

//       setCommulative(updatedCommulative);
//       resetQuestionTimer();
//       setQuestData(null);

//       const payload = {
//         QuestionId: quest.QuestionId,
//         QuestionStatement: quest.QuestionStatement,
//         SubCategory: quest.SubCategory,
//         UserResponse: tempTranscript,
//         mock_test_question_id: quest.MockTestQuestionTableId,
//         time_taken: timeSpent,
//         is_ptecore: false,
//         all_times: JSON.stringify({
//           Category: quest.Category,
//           commulativeTime: updatedCommulative,
//         }),
//         mock_test_attempt_id: MockTestAttemptID || ExamId,
//       };

//       const handleQuestionType = {
//         "Read Aloud": () =>
//           ReadAloud({
//             audiofile: AudioUrl,
//             script: questData.QuestionStatement,
//             user_response: tempTranscript,
//           }),
//         "Repeat Sentence": () =>
//           RepeatSentence({
//             audiofile: AudioUrl,
//             correct_text: questData.QuestionStatement,
//             user_text: tempTranscript,
//           }),
//         "Describe Image": () =>
//           DescribeImage({
//             audiofile: AudioUrl,
//             user_text: tempTranscript,
//             major_aspects: JSON.stringify(questData.MajorAspects.flat()),
//             minor_aspects: JSON.stringify(questData.MinorAspects.flat()),
//           }),
//         "Re-tell Lecture": () =>
//           RetellLecture({
//             audiofile: AudioUrl,
//             script: questData.QuestionStatement,
//             user_response: tempTranscript,
//           }),
//         "Answer Short Question": () =>
//           AnswerShortQuestion({
//             question: questData.QuestionStatement,
//             user_answer: tempTranscript,
//             answer_list: questData.OptionNames,
//           }),
//       };

//       handleQuestionType[quest.SubCategory]()
//         .then((res) => {
//           if (res.data) {
//             MakeQuestionAttempted({
//               ...payload,
//               user_response: JSON.stringify({
//                 ...payload,
//                 AI_response: JSON.stringify(res.data),
//               }),
//               marks_obtained: 100,
//             }).then(() => {
//               if (step === totalQuestions) {
//                 EndTest({ mock_test_attempt_id: MockTestAttemptID || ExamId }).then(() =>
//                   navigate("/MockTest")
//                 );
//               } else {
//                 setStep(step + 1);
//               }
//             });
//           }
//         })
//         .catch(() => {
//           setNetworkError(true);
//           setTimeout(() => navigate("/MockTest"), 2000);
//         });

//       setAudioUrl(null);
//     }
//   }, [AudioUrl, transcriptLoading]);

//   // Format text for Read Aloud
//   function formatText(text) {
//     return text.split("\n").map((line, index) => (
//       <React.Fragment key={index}>
//         {line}
//         <br />
//       </React.Fragment>
//     ));
//   }

//   const handleNext = () => {
//     if (DisabledButton) return;
//     if (audioRef.current) audioRef.current.pause();

//     setClicked((prev) => !prev);
//     setTempTranscript(transcript);
//     setDisabledButton(true);
//     stopStopwatch();
//   };

//   const handleExit = () => {
//     if (audioRef.current) audioRef.current.pause();
//     navigate("/MockTest");
//   };

//   // ----------------- Render ----------------- //
//   return (
//     <div>
//       <Snackbar
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//         autoHideDuration={4000}
//         open={NetworkError}
//         color="danger"
//         onClose={() => setNetworkError(false)}
//       >
//         Network Error
//       </Snackbar>

//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           padding: isLaptopTwo ? "1rem 5% 2rem" : "3rem 5% 2rem",
//         }}
//       >
//         <div
//           style={{
//             backgroundColor: "white",
//             width: "100%",
//             borderRadius: "24px",
//             position: "relative",
//             overflow: "hidden",
//           }}
//         >
//           {/* Progress Bar */}
//           <div style={{ backgroundColor: "#e2e2ea", height: "13px" }}></div>
//           <div
//             style={{
//               width: `${(step / totalQuestions) * 100}%`,
//               backgroundColor: "#996cfe",
//               height: "13px",
//             }}
//           ></div>

//           {Loading ? (
//             <div
//               style={{
//                 justifyContent: "center",
//                 alignItems: "center",
//                 display: "flex",
//                 minHeight: "50vh",
//               }}
//             >
//               <CircularProgress />
//             </div>
//           ) : (
//             <div style={{ padding: "20px", marginTop: "20px" }}>
//               <h1
//                 style={{
//                   fontSize: "20px",
//                   fontWeight: 700,
//                   lineHeight: "32px",
//                 }}
//               >
//                 {guideline[quest.SubCategory]}
//               </h1>

//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   flexDirection: isLaptopOne ? "column" : "row",
//                 }}
//               >
//                 {/* Describe Image */}
//                 {quest.SubCategory === "Describe Image" && (
//                   <div
//                     style={{
//                       minWidth: isLaptopOne ? "100px" : "627px",
//                       minHeight: isLaptopOne ? "100px" : "536px",
//                       backgroundColor: "rgba(153, 108, 254, 0.10)",
//                       borderRadius: "8px",
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                     }}
//                   >
//                     {ImageLoading && <CircularProgress />}
//                     <img
//                       src={questData?.QuestionImage}
//                       alt="img"
//                       onLoad={() => setImageLoading(false)}
//                       style={{
//                         maxWidth: "80%",
//                         maxHeight: "90%",
//                         display: ImageLoading ? "none" : "block",
//                       }}
//                     />
//                   </div>
//                 )}

//                 <div style={{ width: isLaptopOne ? "100%" : "40%" }}>
//                   {questData && (
//                     <RecordingComponent
//                       callNext={handleNext}
//                       setTempTranscript={setTempTranscript}
//                       clicked={clicked}
//                       step={step}
//                       transcript={transcript}
//                       setAudioUrl={setAudioUrl}
//                       setTranscript={setTranscript}
//                       Beginning={
//                         quest.SubCategory === "Read Aloud"
//                         ? 0
//                         : quest.SubCategory === "Answer Short Question"
//                         ? 3
//                         : quest.SubCategory === "Describe Image"
//                         ? 0
//                         : quest.SubCategory === "Re-tell Lecture"
//                         ? 3
//                         : quest.SubCategory === "Respond to a situation"
//                         ? 3
//                         : quest.SubCategory === "Repeat Sentence"
//                         ? 3
//                         : 0
//                       }
//                       Waiting={
//                         quest.SubCategory === "Read Aloud"
//                         ? 35
//                         : quest.SubCategory === "Answer Short Question"
//                         ? 0
//                         : quest.SubCategory === "Describe Image"
//                         ? 25
//                         : quest.SubCategory === "Re-tell Lecture"
//                         ? 10
//                         : quest.SubCategory === "Respond to a situation"
//                         ? 20
//                         : quest.SubCategory === "Repeat Sentence"
//                         ? 0
//                         : 0
//                       }
//                       RecordingTime={
//                         quest.SubCategory === "Read Aloud"
//                         ? 40
//                         : quest.SubCategory === "Answer Short Question"
//                         ? 7
//                         : quest.SubCategory === "Re-tell Lecture"
//                         ? 40
//                         : quest.SubCategory === "Repeat Sentence"
//                         ? 15
//                         : quest.SubCategory === "Respond to a situation"
//                         ? 15
//                         : quest.SubCategory === "Describe Image"
//                         ? 40
//                         : 0
//                       }
//                       audio={
//                         quest.SubCategory === "Describe Image" ||
//                         quest.SubCategory === "Read Aloud"
//                           ? null
//                           : questData.AudioObjects[0]?.audio_url || Filler
//                       }
//                       MicAudioRef={audioRef}
//                       setDisabledButton={setDisabledButton}
//                       mediaRecorderRef={mediaRecorderRef}
//                       setTranscriptLoading={setTranscriptLoading}
//                       stopRecordingProcess={stopRecordingProcess}
//                     />
//                   )}
//                 </div>
//               </div>

//               {/* Read Aloud Text */}
//               {quest.SubCategory === "Read Aloud" && questData?.QuestionStatement && (
//               <h5 style={{ fontSize: "18px", fontWeight: 400, lineHeight: "28px" }}>
//                 {formatText(questData.QuestionStatement)}
//               </h5>
//               )}

//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   marginTop: "45px",
//                 }}
//               >
//                 <SaveExitBtn handleExit={handleExit} />
//                 <PurpleBtn onClick={handleNext} disabled={DisabledButton}>
//                   Next
//                 </PurpleBtn>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }








