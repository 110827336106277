import React, { useEffect, useState, useRef } from "react";
import TestHeading from "../components/Common/TestHeading";
import SSTLogo from "../assets/images/SST_Logo.svg";
import { SST_Subheading, SST_Subheading_Core } from "../components/Common/Data";
import Navbar from "../components/Navbar/Navbar";
import { SWT_QCard_Div } from "./Style";
import TextAreaInput from "../components/Writing/TextAreaInput";
import ButtonList from "../components/Common/ButtonList";
import CommunityScore from "../components/Common/CommunityScore";
import AudioPlayer from "../components/Speaking/AudioPlayer";
import { FlexDiv } from "../assets/styles/style";
import SidePannel from "../components/Common/SidePannel";
import { useAuth } from "../authentication/Auth";
import { useNavigate } from "react-router-dom";
import { AiScorePopupListeningSSTData } from "../components/Listening/data";
import Modal from "react-modal";
import ShowScriptBox from "../components/Common/ShowScriptBox";
Modal.setAppElement("#root");
import { SidePannelBackdrop } from "../components/Common/Style";
import LoadingModal from "../components/Common/LoadingModal";
import { Base_URL, Base_URL_AiScore } from "../Client/apiURL";
import AiSummaryScorePopup from "../components/Writing/AiSummaryScorePopup";

const questionname = "Summarize Spoken Text";

const ListeningSST = () => {
  const pteType = localStorage.getItem("pte-type") || "pte academic";
  const is_ptecore = pteType === "pte academic" ? false : true;

  const [testQuestions, setTestQuestions] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [questionsData, setQuestionsData] = useState(null);
  const [showScript, setShowScript] = useState(false);
  const [questionId, setQuestionID] = useState();
  const [scorecardOpen, setScorecardOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState("");
  const [SummaryScore, setSummaryScore] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [triggerReset, setTriggerReset] = useState(false);
  const [totalTestTime, setTotalTestTime] = useState(is_ptecore ? 480 : 600);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [enableSkillsData, setEnableSkillsData] = useState([]);
  const [scoreDataSubmitted, setScoreDataSubmitted] = useState(false);
  const [testQuestionTableId, setTestQuestionTableId] = useState();
  const [myAttemptedQuestionsScore, setMyAttemptedQuestionsScore] =
    useState(null);
  const [marksObtained, setMarksObtained] = useState(0);
  const [shouldOpenScorecard, setShouldOpenScorecard] = useState(false);
  const [isScoreDataReadyForSubmit, setIsScoreDataReadyForSubmit] =
    useState(false);
  const [lastScoreUpdate, setLastScoreUpdate] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState("");
  const [normalizedText, setNormalizedText] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterPrediction, setFilterPrediction] = useState(false);
  const [wantToSortDesc, setWantToSortDesc] = useState(false);
  const [highFrequency, setHighFrequency] = useState(false);
  const [isPracticed, setIsPracticed] = useState("all");
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [testAttemptedCount, setTestAttemptedCount] = useState(null);
  const [calculatedScore, setCalculatedScore] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAudioPlayerDisabled, setIsAudioPlayerDisabled] = useState(false);
  const audioRef = useRef(null);

  const handleSetLoading = (loadingState) => {
    setIsDataLoading(loadingState);
  };

  useEffect(() => {
    if (questionsData) {
      setIsDataLoading(false);
    }
  }, [questionsData]);

  const handleScoreCalculated = (score) => {
    setCalculatedScore(score);
    setIsScoreDataReadyForSubmit(true);
  };

  const updateLocalQuestionBookmark = (
    testQuestionTableId,
    newBookmarkStatus,
    bookmarkId
  ) => {
    setTestQuestions((prevQuestions) => ({
      ...prevQuestions,
      response: prevQuestions.response.map((question) =>
        question.TestQuestionTableId === testQuestionTableId
          ? {
              ...question,
              IsBookMarked: newBookmarkStatus,
              BookMarkedId: newBookmarkStatus ? bookmarkId : null,
            }
          : question
      ),
    }));
  };

  useEffect(() => {
    const question = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (question) {
      setIsBookmarked(question.IsBookMarked);
      setBookmarkId(question.BookMarkedId);
      setTestAttemptedCount(question.TestAttemptedCount);
    }
  }, [testQuestions, testQuestionTableId]);

  const handleBookmarkChange = (newIsBookmarked, newBookmarkId) => {
    setIsBookmarked(newIsBookmarked);
    setBookmarkId(newBookmarkId);
  };

  useEffect(() => {
    if (
      testQuestions.response &&
      testQuestions.response.length > 0 &&
      currentQuestionIndex < testQuestions.response.length
    ) {
      const question = testQuestions.response[currentQuestionIndex];
      setQuestionID(question.QuestionId);
      setTestQuestionTableId(question.TestQuestionTableId);
    }
  }, [testQuestions, currentQuestionIndex]);

  const handleNextQuestion = () => {
    if (
      currentQuestionIndex <
      (testQuestions.response ? testQuestions.response.length - 1 : 0)
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (page < totalPages) {
      setPage(page + 1);
      setCurrentQuestionIndex(0);
    } else {
      console.log("No more questions to display.");
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (page > 1) {
      setPage(page - 1);
      setCurrentQuestionIndex(testQuestions.response.length - 1);
    } else {
      console.log("No previous questions to display.");
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleMyAttemptedQuestionsScore = (score, openScorecard) => {
    setMyAttemptedQuestionsScore(score);
    if (openScorecard) {
      setShouldOpenScorecard(true);
    }
  };

  useEffect(() => {
    if (shouldOpenScorecard) {
      setScorecardOpen(true);
      setShouldOpenScorecard(false);
    }
  }, [shouldOpenScorecard]);

  useEffect(() => {
    if (questionsData?.response?.QuestionStatement) {
      let newText = questionsData.response.QuestionStatement.replace(
        /%\{option_name\}/g,
        ""
      ).replace(/\\n/g, "");
      // .replace(/#~#~#\./g, " .")
      // .replace(/\.#~#~#/g, ". ");

      setNormalizedText(newText);
    }
  }, [questionsData]);

  useEffect(() => {
    const selectedQuestion = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (selectedQuestion) {
      // setTotalTestTime(selectedQuestion.TotalTime / 1000);
      setTotalTestTime(600);
    }
  }, [questionId, testQuestions]);

  function canSubmit() {
    return false;
  }

  function getQuestionName() {
    const questionTemp = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (questionTemp) {
      return questionTemp.QuestionName;
    } else {
      return "loading";
    }
  }

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    setPage(1);
  }, [filterBookmarked, filterPrediction]);

  useEffect(() => {
    const fetchData = async () => {
      const encodedQuestionName = encodeURIComponent(questionname);
      const searchQueryParam = searchTerm
        ? `&search_name=${encodeURIComponent(searchTerm)}`
        : "";
      const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
      let sortDescParam = highFrequency
        ? ""
        : `&order_by=${wantToSortDesc ? "asc" : "desc"}`;
      let isPracticedParam =
        isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
      let isPredictionParam = filterPrediction
        ? `&prediction=${filterPrediction}`
        : `&prediction=${false}`;
      let isPteCoreParam = false;

      const queryParams = `test_name=${encodedQuestionName}&page=${page}&bookmarked=${filterBookmarked}&is_ptecore=${isPteCoreParam}${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}`;

      try {
        const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
        const response = await fetch(url, { credentials: "include" });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.responseCode === 501) {
          logout();
          navigate("/login");
          return;
        }
        if (data.responseCode === 200) {
          setTestQuestions(data);
          setQuestionID(data?.response[0]?.QuestionId);
          setTestQuestionTableId(data?.response[0]?.TestQuestionTableId);
          const totalQuestions = data.totalQuestions || 1;
          const questionsPerPage = 8;
          const calculatedTotalPages = Math.ceil(
            totalQuestions / questionsPerPage
          );
          setTotalPages(calculatedTotalPages);
        }

        // setTotalTestTime(data.response[0].TotalTime / 1000);
      } catch (error) {
        console.error("Failed to fetch test questions:", error);
      }
    };
    fetchData();
  }, [
    page,
    searchTerm,
    filterBookmarked,
    filterPrediction,
    isPracticed,
    wantToSortDesc,
    highFrequency,
  ]);

  const handleSubmit = async () => {
    if (selectedAnswers.trim() !== "" && questionsData) {
      setIsAudioPlayerDisabled(true);
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setIsLoading(true);

      try {
        // const pteType = localStorage.getItem("pte-type") || "pte academic";
        const payload = {
          passage: questionsData.response.QuestionStatement,
          summary: selectedAnswers,
          pte_type: pteType,
        };
        const response = await fetch(`${Base_URL_AiScore}/summarizespoken`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data) {
          setSummaryScore(data);
          setIsSubmitted(true);
          setScorecardOpen(true);
          // setIsScoreDataReadyForSubmit(true);
        } else {
          logout();
          navigate("/login");
        }
      } catch (error) {
        console.error("Failed to fetch essay score:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("scorecard");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setScorecardOpen(false);
        setElapsedTime(0);
        setEnableSkillsData([]);
        setMyAttemptedQuestionsScore(null);
        setMarksObtained(0);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    async function submitScoreData() {
      const pteType = localStorage.getItem("pte-type") || "pte academic";
      const is_ptecore = pteType === "pte academic" ? false : true;

      const userResponse = {
        correctAnswers: questionsData?.response?.AnswerNames,
        selectedAnswers: selectedAnswers,
        enableSkillsData: SummaryScore,
      };

      const payload = {
        test_question_id: testQuestionTableId,
        marks_obtained: calculatedScore,
        user_response: JSON.stringify(userResponse),
        time_taken: elapsedTime,
        is_ptecore: is_ptecore,
      };

      try {
        const response = await fetch(
          `${Base_URL}/app/users/attempted-questions/add`,
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        if (data.responseCode === 200) {
          console.log(data.message);
          setScoreDataSubmitted(true);
          setIsScoreDataReadyForSubmit(false);
          setLastScoreUpdate(Date.now());
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Failed to submit score data:", error);
      }
    }

    const shouldSubmitScore =
      isScoreDataReadyForSubmit && !scoreDataSubmitted && elapsedTime;
    if (shouldSubmitScore) {
      submitScoreData();
    }
  }, [
    isScoreDataReadyForSubmit,
    scoreDataSubmitted,
    elapsedTime,
    calculatedScore,
  ]);

  const resetState = () => {
    setSelectedAnswers("");
    setShowScript(false);
    setScorecardOpen(false);
    setElapsedTime(0);
    setIsSubmitted(false);
    setTotalTestTime(totalTestTime);
    setTriggerReset((prevState) => !prevState);
    setEnableSkillsData([]);
    setScoreDataSubmitted(false);
    setMyAttemptedQuestionsScore(null);
    setMarksObtained(0);
    setShouldOpenScorecard(false);
    setIsAudioPlayerDisabled(false);
  };

  useEffect(() => {
    resetState();
  }, [questionId]);

  const handleRedo = () => {
    resetState();
  };

  const modalStyle = {
    overlay: {
      zIndex: 1002,
      backdropFilter: "blur(5px)",
      WebkitBackdropFilter: "blur(5px)",
      background: "none",
    },
    content: {
      border: "none",
      background: "transparent",
      inset: "0",
      padding: "20px 1%",
    },
  };

  const calculateDaysDifference = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);
    const timeDiff = currentDate - createdDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff < 60;
  };

  return (
    <>
      {isDataLoading && <LoadingModal />}
      <div id="scorecardd">
        {scorecardOpen && (
          <Modal isOpen={scorecardOpen} style={modalStyle}>
            <AiSummaryScorePopup
              key={"SummaryPopup"}
              // key={AiScoreCardSWTData[0].key}
              EnableSkillsScore={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .enableSkillsData
                  : SummaryScore
              }
              SmallScoreCard={AiScorePopupListeningSSTData[0].SmallScoreCard}
              UserResponse={
                myAttemptedQuestionsScore
                  ? {
                      textValue: JSON.parse(
                        myAttemptedQuestionsScore.UsersResponse
                      ).selectedAnswers,
                    }
                  : { textValue: selectedAnswers }
              }
              elapsedTime={
                myAttemptedQuestionsScore
                  ? myAttemptedQuestionsScore.TimeTaken
                  : elapsedTime
              }
              grammarKeyName={
                pteType === "pte-academic"
                  ? "grammatical Mistakes"
                  : "grammatical mistakes"
              }
              grammarIndexKeyName="grammatical mistakes indices"
              mispelledIndex="misspelled words indices"
              // summary={true}
              summaryST={true}
              pte_core={pteType === "pte-academic" ? false : true}
              onScoreCalculated={handleScoreCalculated}
              close={setScorecardOpen}
              isOpen={scorecardOpen}
              reqIndexNum={2}
              essay={false}
            />
          </Modal>
        )}
      </div>
      {isSidePanelOpen && <SidePannelBackdrop isOpen={isSidePanelOpen} />}
      <SidePannel
        onToggle={setIsSidePanelOpen}
        testQuestions={testQuestions.response || []}
        totalTestQuestions={testQuestions.totalQuestions}
        heading={"Summarize Spoken Text"}
        setQuestionsData={setQuestionsData}
        questionID={questionId}
        setQuestionID={setQuestionID}
        setSelectedAnswers={setSelectedAnswers}
        setTriggerReset={setTriggerReset}
        logo={SSTLogo}
        setTestQuestionTableId={setTestQuestionTableId}
        testQuestionTableId={testQuestionTableId}
        handleSearchChange={handleSearchChange}
        onPageChange={handlePageChange}
        currentPage={page}
        totalPages={totalPages}
        updateLocalQuestionBookmark={updateLocalQuestionBookmark}
        setFilterBookmarked={setFilterBookmarked}
        setFilterPrediction={setFilterPrediction}
        setWantToSortDesc={setWantToSortDesc}
        setHighFrequency={setHighFrequency}
        setIsPracticed={setIsPracticed}
        setDataLoading={handleSetLoading}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
      />
      <Navbar />
      <FlexDiv style={{ width: "100%" }}>
        <FlexDiv
          style={{ flexDirection: "column", width: "100%", maxWidth: "1880px" }}
        >
          <TestHeading
            logo={SSTLogo}
            heading={"Summarize Spoken Text"}
            subheading={is_ptecore ? SST_Subheading_Core : SST_Subheading}
            serialNo={questionId ? "#" + questionId : "#000"}
            questionName={getQuestionName()}
            isSubmitted={isSubmitted}
            timerMode="decremental"
            totalTestTime={totalTestTime}
            setElapsedTime={setElapsedTime}
            triggerReset={triggerReset}
            testQuestionTableId={testQuestionTableId}
            appearedCount={questionsData?.response?.AppearedCount}
            IsBookMarked={isBookmarked}
            BookMarkedId={bookmarkId}
            onBookmarkChange={handleBookmarkChange}
            questionID={questionId}
            dictionaryText={normalizedText}
            testAttemptedCount={testAttemptedCount}
            isPrediction={questionsData?.response?.Prediction}
            isNew={calculateDaysDifference(questionsData?.response?.CreatedAt)}
          />
          {questionsData && questionsData.response && (
            <>
              <SWT_QCard_Div>
                <AudioPlayer
                  AudioObjects={questionsData.response.AudioObjects}
                  resetTrigger={triggerReset}
                  isAudioPlayerDisabled={isAudioPlayerDisabled}
                  audioRef={audioRef}
                />
              </SWT_QCard_Div>
              <SWT_QCard_Div>
                <TextAreaInput
                  key={`Text-area-input-${triggerReset}`}
                  // renderWC={false}
                  // height={"200px"}
                  setSelectedAnswers={setSelectedAnswers}
                  isDisabled={isSubmitted}
                />
              </SWT_QCard_Div>
            </>
          )}
          <ButtonList
            onSubmit={() => {
              handleSubmit();
            }}
            onRedo={() => handleRedo()}
            onScript={() => setShowScript(!showScript)}
            canSubmit={canSubmit}
            isLoading={isLoading}
            renderAnswer={false}
            renderScript={true}
            isSubmitted={isSubmitted}
            onNext={handleNextQuestion}
            onPrevious={handlePreviousQuestion}
            hasPrevious={currentQuestionIndex > 0 || page > 1}
            hasNext={
              currentQuestionIndex <
                (testQuestions.response
                  ? testQuestions.response.length - 1
                  : 0) || page < totalPages
            }
            handleSearchChange={handleSearchChange}
          />
          {showScript && questionsData?.response?.QuestionStatement && (
            <ShowScriptBox
              answerText={questionsData?.response?.QuestionStatement}
            />
          )}
          <CommunityScore
            is_ptecore={pteType === "pte academic" ? false : true}
            ScoreLetter="L"
            bg="#868EAF"
            totalScore="10"
            testQuestionTableId={testQuestionTableId}
            onSelectMyScore={handleMyAttemptedQuestionsScore}
            lastScoreUpdate={lastScoreUpdate}
            testSubmissionInProcess={isLoading}
          />
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

export default ListeningSST;
