import React from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeIn = styled.span`
  display: inline-block;
  animation: ${fadeIn} 0.5s ease forwards;
  opacity: 0; 
`;

const PortalName = ({ isOtherPortal, data }) => {
  if (typeof isOtherPortal !== "boolean") {
    return null;
  }

  let content = null;

  if (isOtherPortal === false) {
    content = "Swiftuni";
  } else if (isOtherPortal === true) {
    const portalName = data?.portal_name ? data.portal_name.charAt(0).toUpperCase() + data.portal_name.slice(1) : null;
    if (portalName) {
      content = <FadeIn>{portalName}</FadeIn>;
    }
  }

  return <>Sign in to {content}</>;
};

export default PortalName;
