import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { FlexDiv } from "../../assets/styles/style";
import {
  AnalyticsHeader,
  FeedbackHeaderTitle,
  WhiteDiv,
  CircleDiv,
  CircleScoresDiv,
  PointImg,
  ScoreDigit,
  PointAndScore,
  ImgAndLine,
} from "./style";
import ReadingImg from "../../assets/todaytaskcard/todaytaskimg1.svg";
import ListeningImg from "../../assets/todaytaskcard/todaytaskimg2.svg";
import WritingImg from "../../assets/todaytaskcard/todaytaskimg3.svg";
import SpeakingImg from "../../assets/todaytaskcard/todaytaskimg4.svg";
import DividerLine from "../../assets/images/dividersvg.svg";
import ReadingPoint from "../../assets/images/readingpoint.svg";
import WritingPoint from "../../assets/images/writingpoint.svg";
import ListeningPoint from "../../assets/images/listeningpoint.svg";
import SpeakingPoint from "../../assets/images/speakingpoint.svg";
import AnalyticsTestCard from "./AnalyticsTestCard";
import AnalyticsCircle from "./AnalyticsCircle";
import AnalyticsForMtScore from "./AnalyticsForMtScore";
import AnalyticsTestCard2 from "./AnalyticsTestCard2";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingModal from "../Common/LoadingModal";
import { PieChart } from "react-minimal-pie-chart";
import { Base_URL } from "../../Client/apiURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import AnalyticsForMtScoreSectional from "./AnalyticsForMtScoreSectional";
import { fetchMockTestScore } from "./helperFunctions";

function convertSecondsToMMSS(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return isNaN(formattedSeconds)
    ? "00:00"
    : `${formattedMinutes}:${formattedSeconds}`;
}

const SpeakingQuestionNames = [
  "Read Aloud",
  "Repeat Sentence",
  "Describe Image",
  "Re-tell Lecture",
  "Answer Short Question",
];

const WritingQuestionNames = ["Summarize Written Text", "Write Essay"];

const ReadingQuestionNames = [
  "Reading and Writing: Fill in the Blanks",
  "Multiple Choice, Multiple Answers",
  "Re-order Paragraphs",
  "Reading: Fill in the Blanks",
  "Multiple Choice, Single Answer",
];

const ListeningQuestionNames = [
  "Summarize Spoken Text",
  "Listening: Multiple Choice, Multiple Answers",
  "Listening: Fill in the Blanks",
  "Highlight Correct Summary",
  "Listening: Multiple Choice, Single Answer",
  "Select Missing Word",
  "Highlight Incorrect Words",
  "Write from Dictation",
];

const ListenQuestionNames = [
  "Summarize Spoken Text",
  "Multiple Choice, Multiple Answers",
  "Fill in the Blanks",
  "Highlight Correct Summary",
  "Multiple Choice, Single Answer",
  "Select Missing Word",
  "Highlight Incorrect Words",
  "Write from Dictation",
];

const AnalyticsSectional = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isTab = useMediaQuery("(max-width:1000px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [mockTestsResult, setMockTestsResult] = useState([]);
  const [testCounts, setTestCounts] = useState(null);

  const [testCountsNew, setTestCountsNew] = useState({
    speaking: { attempted: 0, total: 0 },
    writing: { attempted: 0, total: 0 },
    reading: { attempted: 0, total: 0 },
    listening: { attempted: 0, total: 0 },
  });
  
  const globalTotal = Object.values(testCountsNew).reduce((acc, cat) => acc + cat.total, 0);
  const globalAttempted = Object.values(testCountsNew).reduce((acc, cat) => acc + cat.attempted, 0);

  // const pieData = [
  //   { title: "Speaking",   value: testCountsNew.speaking.total,   color: "#49D7F2" },
  //   { title: "Writing",    value: testCountsNew.writing.total,    color: "#FF5D5D" },
  //   { title: "Reading",    value: testCountsNew.reading.total,    color: "#AD826E" },
  //   { title: "Listening",  value: testCountsNew.listening.total,  color: "#868EAF" },
  // ].filter((entry) => entry.value > 0);

  const pieData = [
    { title: "Speaking",   value: testCountsNew.speaking.attempted,   color: "#49D7F2" },
    { title: "Writing",    value: testCountsNew.writing.attempted,    color: "#FF5D5D" },
    { title: "Reading",    value: testCountsNew.reading.attempted,    color: "#AD826E" },
    { title: "Listening",  value: testCountsNew.listening.attempted,  color: "#868EAF" },
  ].filter((entry) => entry.value > 0);

  const [speakingScore, setSpeakingScore] = useState(0);
  const [writingsScore, setWritingScore] = useState(0);
  const [readingsScore, setReadingScore] = useState(0);
  const [listeningsScore, setListeningScore] = useState(0);
  const [testTimimgs, setTestTimings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mockTestType, setMockTestType] = useState("");

  const url = `${Base_URL}/app/users/mock-test-attempts/get-score?mock_test_attempted_id=${id}`;
  const withCredentials = true;

  useEffect(() => {
    const fetchAndHandleScoreData = async () => {
      const result = await fetchMockTestScore({
        url,
        setIsLoading,
        setMockTestsResult,
        splitCategories,
        setMockTestType,
        withCredentials,
      });

      if (result.success) {
        // toast.success(result.message);
        const counts = getCountsByCategory(result.data);
        setTestCountsNew(counts);
      } else {
        navigate("/mt-score");
        toast.error(result.message);
      }
    };

    fetchAndHandleScoreData();
  }, []);

  const getCountsByCategory = (responses) => {
    const counts = {
      speaking:   { attempted: 0, total: 0 },
      writing:    { attempted: 0, total: 0 },
      reading:    { attempted: 0, total: 0 },
      listening:  { attempted: 0, total: 0 },
    };
  
    responses.forEach((item) => {
      const cat = item.Category?.toLowerCase();
      if (!cat || !counts[cat]) return;
  
      counts[cat].total++;
  
      if (item.UserResponse?.IsAttempted !== false) {
        counts[cat].attempted++;
      }
    });
    
    return counts;
  };

  const splitCategories = (data) => {
    const categorizedData = data.reduce(
      (acc, curr) => {
        switch (curr.UserResponse.SubCategory) {
          case "Write Essay":
            acc.writing.push(curr);
            acc.we.push(curr);
            break;
          case "Summarize Written Text":
            acc.writing.push(curr);
            acc.swt.push(curr);
            acc.reading.push(curr);
            break;
          case "Reading & Writing: Fill in the Blanks":
            acc.writing.push(curr);
            acc.reading.push(curr);
            acc.rwfib.push(curr);
            break;
          case "Summarize Spoken Text":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.sst.push(curr);
            break;
          case "Fill in the Blanks":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.lfib.push(curr);
            break;
          case "Write from Dictation":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.wfd.push(curr);
            break;
          case "Read Aloud":
            acc.reading.push(curr);
            acc.speaking.push(curr);
            acc.ra.push(curr);
            break;
          case "Multiple Choice, Multiple Answers":
            acc.reading.push(curr);
            acc.mcma.push(curr);
            break;
          case "Re-order Paragraphs":
            acc.reading.push(curr);
            acc.rop.push(curr);
            break;
          case "Multiple Choice, Single Answer":
            acc.reading.push(curr);
            acc.mcsa.push(curr);
            break;
          case "Highlight Correct Summary":
            acc.reading.push(curr);
            acc.listening.push(curr);
            acc.hcs.push(curr);
            break;
          case "Highlight Incorrect Words":
            acc.reading.push(curr);
            acc.listening.push(curr);
            acc.hiw.push(curr);
            break;
          case "Reading: Fill in the Blanks":
            acc.reading.push(curr);
            acc.rfib.push(curr);
            break;
          case "Repeat Sentence":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.rs.push(curr);
            break;
          case "Describe Image":
            acc.speaking.push(curr);
            acc.di.push(curr);
            break;
          case "Re-tell Lecture":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.rl.push(curr);
            break;
          case "Answer Short Question":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.asq.push(curr);
            break;
          default:
            acc.listening.push(curr);
            switch (curr.UserResponse.SubCategory) {
              case "Listening: Multiple Choice, Multiple Answers":
                acc.lmcma.push(curr);
                break;
              case "Listening: Multiple Choice, Single Answer":
                acc.lmcsa.push(curr);
                break;
              default:
                acc.smw.push(curr);
                break;
            }
            break;
        }
        return acc;
      },
      {
        listening: [],
        reading: [],
        writing: [],
        speaking: [],
        ra: [],
        rs: [],
        di: [],
        rl: [],
        asq: [],
        swt: [],
        we: [],
        rwfib: [],
        rfib: [],
        mcma: [],
        mcsa: [],
        rop: [],
        sst: [],
        lmcma: [],
        lmcsa: [],
        hiw: [],
        lfib: [],
        wfd: [],
        hcs: [],
        smw: [],
      }
    );

    setTestCounts([
      categorizedData.ra,
      categorizedData.rs,
      categorizedData.di,
      categorizedData.rl,
      categorizedData.asq,
      categorizedData.swt,
      categorizedData.we,
      categorizedData.rwfib,
      categorizedData.rfib,
      categorizedData.mcma,
      categorizedData.mcsa,
      categorizedData.rop,
      categorizedData.sst,
      categorizedData.lmcma,
      categorizedData.lmcsa,
      categorizedData.hiw,
      categorizedData.lfib,
      categorizedData.wfd,
      categorizedData.hcs,
      categorizedData.smw,
    ]);
    setTestTimings({
      "Read Aloud": categorizedData.ra,
      "Repeat Sentence": categorizedData.rs,
      "Describe Image": categorizedData.di,
      "Re-tell Lecture": categorizedData.rl,
      "Answer Short Question": categorizedData.asq,
      "Summarize Written Text": categorizedData.swt,
      "Write Essay": categorizedData.we,
      "Reading and Writing: Fill in the Blanks": categorizedData.rwfib,
      "Reading: Fill in the Blanks": categorizedData.rfib,
      "Multiple Choice, Multiple Answers": categorizedData.mcma,
      "Multiple Choice, Single Answer": categorizedData.mcsa,
      "Re-order Paragraphs": categorizedData.rop,
      "Summarize Spoken Text": categorizedData.sst,
      "Listening: Multiple Choice, Multiple Answers": categorizedData.lmcma,
      "Listening: Multiple Choice, Single Answer": categorizedData.lmcsa,
      "Highlight Incorrect Words": categorizedData.hiw,
      "Listening: Fill in the Blanks": categorizedData.lfib,
      "Write from Dictation": categorizedData.wfd,
      "Highlight Correct Summary": categorizedData.hcs,
      "Select Missing Word": categorizedData.smw,
    });
  };

  const getTimeTaken = (testName = "Read Aloud", mmss = true) => {
    if (testTimimgs && testTimimgs[testName]) {
      const time = testTimimgs[testName].reduce(
        (acc, test) => acc + test.TimeTaken,
        0
      );
      return Number.isInteger(mmss) ? convertSecondsToMMSS(time) : time;
    } else {
      return "00:00";
    }
  };

  const getSpeakingTimeTaken = () => SpeakingQuestionNames.map(getTimeTaken);

  const getTotalSpeakingTimeTaken = () => {
    let tot = 0;
    SpeakingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getWritingTimeTaken = () => WritingQuestionNames.map(getTimeTaken);

  const getTotalWritingTimeTaken = () => {
    let tot = 0;
    WritingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getReadingTimeTaken = () => ReadingQuestionNames.map(getTimeTaken);

  const getTotalReadingTimeTaken = () => {
    let tot = 0;
    ReadingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getListeningTimeTaken = () => ListeningQuestionNames.map(getTimeTaken);

  const getTotalListeningTimeTaken = () => {
    let tot = 0;
    ListeningQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getTotalTimeTaken = () => {
    let tot = 0;
    [
      ListeningQuestionNames,
      ReadingQuestionNames,
      WritingQuestionNames,
      SpeakingQuestionNames,
    ].forEach((questionNames) => {
      questionNames.forEach((test) => {
        tot += getTimeTaken(test, false);
      });
    });

    return convertSecondsToMMSS(tot);
  };
  
  // const listeningScore = () => {
  //   const parsedData = listeningTests.map(test => {
  //     if (test.UserResponse.AI_response) {
  //       return {
  //         ...test,
  //         UserResponse: {
  //           ...test.UserResponse,
  //           AI_response: JSON.parse(test.UserResponse.AI_response),
  //         }
  //       };
  //     } else {
  //       return test;
  //     }
  //   });
  //   // console.log(parsedData)
  //   let totalScore = 0;
  //   let obtainedScore = 0;
  //   parsedData.forEach(test => {
  //     if (test.UserResponse?.SubCategory === 'Summarize Spoken Text') {
  //       obtainedScore += test.UserResponse?.AI_response.total_score;
  //       totalScore += 7;
  //     }else if (test.UserResponse?.SubCategory === 'Fill in the Blanks') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += test.UserResponse?.correctAnswers?.length || 0;
  //     }else if (test.UserResponse?.SubCategory === 'Write from Dictation') {
  //       obtainedScore += test.UserResponse?.AI_response.writing_score;
  //       totalScore += test.UserResponse?.AI_response?.total_score;
  //     }else if (test.UserResponse?.SubCategory === 'Highlight Correct Summary') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += 1;
  //     }else if (test.UserResponse?.SubCategory === 'Highlight Incorrect Words') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += test.UserResponse?.correctAnswers?.length || 0;
  //     }
  //     else if(test['UserResponse']['SubCategory'] === "Listening: Multiple Choice, Multiple Answers"){
  //       let tmp = test['UserResponse'];
  //       totalScore += tmp.correctAnswers.length;
  //       let score = tmp.enableSkillsData[0]['score'];
  //       obtainedScore += parseInt(score[0])
  //     }else if(test['UserResponse']['SubCategory'] === "Listening: Multiple Choice, Single Answer"){
  //       let tmp = test['UserResponse'];
  //       totalScore += 1;
  //       let score = tmp.enableSkillsData[0]['score'];
  //       obtainedScore += parseInt(score[0])
  //     }
  //     else if(test['UserResponse']['SubCategory'] === "Re-tell Lecture"){
  //       let tmp = test['UserResponse']['AI_response'];
  //       totalScore += 15;
  //       let obtain = tmp.content_score + tmp.fluency_score + tmp.pronunciation_score
  //       obtainedScore += obtain;
  //     }
  //     else if(test['UserResponse']['SubCategory'] === "Answer Short Question"){
  //       // let tmp = JSON.parse(result['UserResponse']['AI_response']);
  //       totalScore += 1;
  //       obtainedScore += 0;
  //     }
  //     else if(test['UserResponse']['SubCategory'] === "Repeat Sentence"){
  //       let tmp = test['UserResponse']['AI_response'];
  //       totalScore += 15;
  //       let obtain = tmp.content_score + tmp.fluency_score + tmp.pronunciation_score
  //       obtainedScore += obtain;
  //     }
  //     else if(test['UserResponse']['SubCategory'] === "Select Missing Word"){
  //       let tmp = test['UserResponse'];
  //       totalScore += 1;
  //       let score = tmp.enableSkillsData[0]['score'];
  //       obtainedScore += parseInt(score[0])
  //     }
  //   })
  //   if(totalScore > 0){
  //     obtainedScore = parseInt((obtainedScore/totalScore) * 90);
  //   }
  //   return obtainedScore > 10 ? obtainedScore : 10;
  // }

  // const readingScore = () => {
  //   const parsedData = readingTests.map(test => {
  //     if (test.UserResponse.AI_response) {
  //       return {
  //         ...test,
  //         UserResponse: {
  //           ...test.UserResponse,
  //           AI_response: JSON.parse(test.UserResponse.AI_response),
  //         }
  //       };
  //     } else {
  //       return test;
  //     }
  //   });

  //   let totalScore = 0;
  //   let obtainedScore = 0;
  //   parsedData.forEach(test => {
  //     if (test.UserResponse?.SubCategory === 'Read Aloud') {
  //       obtainedScore += 8;
  //       totalScore += 13;
  //     } else if (test.UserResponse?.SubCategory === 'Summarize Written Text') {
  //       obtainedScore += test.UserResponse?.AI_response.total_score;
  //       totalScore += 7;
  //     }else if (test.UserResponse?.SubCategory === 'Reading & Writing: Fill in the Blanks') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += test.UserResponse?.correctAnswers?.length || 0;
  //     }else if (test.UserResponse?.SubCategory === 'Multiple Choice, Multiple Answers') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += test.UserResponse?.correctAnswers?.length || 0;
  //     }else if (test.UserResponse?.SubCategory === 'Re-order Paragraphs') {
  //       obtainedScore += test.UserResponse?.submissionResult?.score || 0;
  //       totalScore += test.UserResponse?.submissionResult?.correctIndexes?.length || 0;
  //     }else if (test.UserResponse?.SubCategory === 'Multiple Choice, Single Answer') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += 1;
  //     }else if (test.UserResponse?.SubCategory === 'Reading: Fill in the Blanks') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += test.UserResponse?.correctAnswers?.length || 0;
  //     }else if (test.UserResponse?.SubCategory === 'Highlight Correct Summary') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += 1;
  //     }else if (test.UserResponse?.SubCategory === 'Highlight Incorrect Words') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += test.UserResponse?.correctAnswers?.length || 0;
  //     }
  //   });
  //   if(totalScore > 0){
  //     obtainedScore = parseInt((obtainedScore/totalScore) * 90);
  //   }
  //   return obtainedScore;
  // }

  // const writingScore = () => {
  //   const parsedData = writingTests.map(test => {
  //     if (test.UserResponse.AI_response) {
  //       return {
  //         ...test,
  //         UserResponse: {
  //           ...test.UserResponse,
  //           AI_response: JSON.parse(test.UserResponse.AI_response),
  //         }
  //       };
  //     } else {
  //       return test;
  //     }
  //   });
  //   let totalScore = 0;
  //   let obtainedScore = 0;
  //   parsedData.forEach(test => {
  //     if (test.UserResponse?.SubCategory === 'Summarize Written Text') {
  //       obtainedScore += test.UserResponse?.AI_response.total_score;
  //       totalScore += 7;
  //     } else if (test.UserResponse?.SubCategory === 'Write Essay') {
  //       obtainedScore += test.UserResponse?.AI_response.total_score;
  //       totalScore += 15;
  //     }else if (test.UserResponse?.SubCategory === 'Summarize Spoken Text') {
  //       obtainedScore += test.UserResponse?.AI_response.total_score;
  //       totalScore += 10;
  //     }else if (test.UserResponse?.SubCategory === 'Write from Dictation') {
  //       obtainedScore += test.UserResponse?.AI_response.writing_score;
  //       totalScore += test.UserResponse?.AI_response?.total_score;
  //     }else if (test.UserResponse?.SubCategory === 'Fill in the Blanks') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += test.UserResponse?.correctAnswers?.length || 0;
  //     }else if (test.UserResponse?.SubCategory === 'Reading & Writing: Fill in the Blanks') {
  //       let temp = (test.UserResponse?.enableSkillsData[0]?.score?.split('/')[0]);
  //       obtainedScore += parseInt(temp || 0);
  //       totalScore += test.UserResponse?.correctAnswers?.length || 0;
  //     }
  //   });
  //   if(totalScore > 0){
  //     obtainedScore = parseInt((obtainedScore/totalScore) * 90);
  //   }
  //   return [obtainedScore >10?obtainedScore:10, 90];
  // }

  return mockTestsResult && mockTestsResult[0]?.MockTestName ? (
    <>
      <FlexDiv
        style={{
          flexDirection: "column",
          padding: isTab ? "1.5rem 2% 2rem" : "6.5rem 3% 2rem",
        }}
      >
        <AnalyticsHeader>
          <FeedbackHeaderTitle>
            ANALYTICS: <span>{mockTestsResult[0]?.MockTestName}</span>
          </FeedbackHeaderTitle>
        </AnalyticsHeader>
        <WhiteDiv>
          <FlexDiv
            style={{
              padding: "2.5rem 0rem 0rem ",
              width: "96%",
              flexDirection: isTab ? "column" : "row",
              gap: "1.52rem",
            }}
          >
            <FlexDiv
              style={{
                justifyContent: isTab ? "center" : "flex-end",
                width: "50%",
              }}
            >
              <CircleDiv>
                {/* <AnalyticsCircle /> */}

                <PieChart
                  label={({ dataEntry }) => {
                    const percent = globalTotal
                      ? ((dataEntry.value / globalTotal) * 100).toFixed(1)
                      : 0;
                    return `${dataEntry.title}: ${percent}% Qs`;
                  }}
                  labelStyle={{
                    fill: "white",
                    fontSize: "5px",
                    fontFamily: "Helvetica Neue, sans-serif",
                    textShadow: "1px 1px 4px #000000AA",
                    pointerEvents: "none",
                  }}
                  labelPosition={68}
                  lineWidth={60}
                  radius={50}
                  data={pieData}
                />

                <ImgAndLine>
                  <img
                    src={DividerLine}
                    alt=""
                    style={{ width: isMobile ? "" : "100%" }}
                  />

                  <CircleScoresDiv style={{ justifyContent: "center" }}>
                    {mockTestType === "Speaking" && (
                      <PointAndScore>
                        <PointImg src={SpeakingPoint} alt="" />
                        <ScoreDigit color={"#49D7F2"}>
                        {globalAttempted || 0} Questions Attempted
                        </ScoreDigit>
                      </PointAndScore>
                    )}
                    {mockTestType === "Writing" && (
                      <PointAndScore>
                        <PointImg src={WritingPoint} alt="" />
                        <ScoreDigit color={"#FF5D5D"}>
                        {globalAttempted || 0} Questions Attempted
                        </ScoreDigit>
                      </PointAndScore>
                    )}
                    {mockTestType === "Reading" && (
                      <PointAndScore>
                        <PointImg src={ReadingPoint} alt="" />
                        <ScoreDigit color={"#AD826E"}>
                        {globalAttempted || 0} Questions Attempted
                        </ScoreDigit>
                      </PointAndScore>
                    )}
                    {mockTestType === "Listening" && (
                      <PointAndScore>
                        <PointImg src={ListeningPoint} alt="" />
                        <ScoreDigit color={"#868EAF"}>
                        {globalAttempted || 0} Questions Attempted
                        </ScoreDigit>
                      </PointAndScore>
                    )}
                  </CircleScoresDiv>

                </ImgAndLine>
              </CircleDiv>
            </FlexDiv>
            <FlexDiv
              style={{ flexDirection: "column", gap: "1.25rem", width: "50%" }}
            >
              <FlexDiv
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  gap: "1.25rem",
                  width: "100%",
                  justifyContent: isTab ? "center" : "flex-start",
                }}
              >
                {mockTestType === "Speaking" && (
                  <AnalyticsTestCard
                    headerName="Speaking"
                    imgSrc={SpeakingImg}
                    color="#49D7F2"
                    score={speakingScore || 0}
                    questions={`${globalAttempted || 0}/${globalTotal || 0}`}
                  />
                )}
                {mockTestType === "Writing" && (
                  <AnalyticsTestCard
                    headerName="Writing"
                    imgSrc={WritingImg}
                    color="#FF5D5D"
                    score={writingsScore || 0}
                    questions={`${globalAttempted || 0}/${globalTotal || 0}`}
                  />
                )}
                {mockTestType === "Reading" && (
                  <AnalyticsTestCard
                    headerName="Reading"
                    imgSrc={ReadingImg}
                    color="#AD826E"
                    score={readingsScore || 0}
                    questions={`${globalAttempted || 0}/${globalTotal || 0}`}
                  />
                )}
                {mockTestType === "Listening" && (
                  <AnalyticsTestCard
                    headerName="Listening"
                    imgSrc={ListeningImg}
                    color="#868EAF"
                    score={listeningsScore || 0}
                    questions={`${globalAttempted || 0}/${globalTotal || 0}`}
                  />
                )}
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv style={{ width: isMobile ? "95%" : "100%" }}>
            <AnalyticsForMtScoreSectional
              counts={testCounts}
              mockTestType={mockTestType}
              speakingTotScore={setSpeakingScore}
              writingTotScore={setWritingScore}
              readingTotScore={setReadingScore}
              listeningTotScore={setListeningScore}
            />
          </FlexDiv>
        </WhiteDiv>
      </FlexDiv>
      <FlexDiv
        style={{
          flexDirection: "column",
          padding: isTab ? "0rem 2% 2rem" : "0rem 3% 2rem",
        }}
      >
        <AnalyticsHeader>
          <FeedbackHeaderTitle>
            TIME TAKEN: <span>{getTotalTimeTaken()}</span>{" "}
          </FeedbackHeaderTitle>
        </AnalyticsHeader>
        <WhiteDiv>
        <FlexDiv
            style={{
              flexDirection: "column",
              gap: "1.25rem",
              width: "92%",
              padding: "2.5rem 0rem",
             
            }}
          >
            {mockTestType === "Speaking" && (
              <>
                <FlexDiv
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: "1.25rem",
                    width: "100%",
                    alignItems: "flex-start",
                  
                  }}
                >
                  <AnalyticsTestCard2
                    headerName="Speaking"
                    imgSrc={SpeakingImg}
                    color="#49D7F2"
                    QuestinsTotalTime={getTotalSpeakingTimeTaken()}
                    // QuestionNames={SpeakingQuestionNames}
                    QuestionNames={SpeakingQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    // QuestionTimes={getSpeakingTimeTaken()}
                    QuestionTimes={getSpeakingTimeTaken().filter((_, index) =>
                      SpeakingQuestionNames.some((name) =>
                        mockTestsResult.some(
                          (result) =>
                            result.UserResponse &&
                            result.UserResponse.SubCategory === name
                        )
                      )
                    )}
                  />
                </FlexDiv>
              </>
            )}

            {mockTestType === "Writing" && (
              <>
                <FlexDiv
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: "1.25rem",
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "left",
                  }}
                >
                  <AnalyticsTestCard2
                    headerName="Writing"
                    imgSrc={WritingImg}
                    color="#FF5D5D"
                    QuestinsTotalTime={getTotalWritingTimeTaken()}
                    // QuestionNames={WritingQuestionNames}
                    QuestionNames={WritingQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    QuestionTimes={getWritingTimeTaken()}
                  />

                  <AnalyticsTestCard2
                    headerName="Reading"
                    imgSrc={ReadingImg}
                    color="#AD826E"
                    QuestinsTotalTime={getTotalReadingTimeTaken()}
                    // QuestionNames={ReadingQuestionNames}
                    QuestionNames={ReadingQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    QuestionTimes={getReadingTimeTaken()}
                  />

                </FlexDiv>

                <FlexDiv
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: "1.25rem",
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "left",
                  }}
                >
                  <AnalyticsTestCard2
                    headerName="Listening"
                    imgSrc={ListeningImg}
                    color="#868EAF"
                    QuestinsTotalTime={getTotalListeningTimeTaken()}
                    // QuestionNames={ListenQuestionNames}
                    QuestionNames={ListenQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    QuestionTimes={getListeningTimeTaken()}
                  />
                </FlexDiv>
              </>
            )}

            {mockTestType === "Reading" && (
              <>
                <FlexDiv
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: "1.25rem",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                  <AnalyticsTestCard2
                    headerName="Reading"
                    imgSrc={ReadingImg}
                    color="#AD826E"
                    QuestinsTotalTime={getTotalReadingTimeTaken()}
                    // QuestionNames={ReadingQuestionNames}
                    QuestionNames={ReadingQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    QuestionTimes={getReadingTimeTaken()}
                  />
                  <AnalyticsTestCard2
                    headerName="Writing"
                    imgSrc={WritingImg}
                    color="#FF5D5D"
                    QuestinsTotalTime={getTotalWritingTimeTaken()}
                    // QuestionNames={WritingQuestionNames}
                    QuestionNames={WritingQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    QuestionTimes={getWritingTimeTaken()}
                  />
                </FlexDiv>

                <FlexDiv
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: "1.25rem",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                <AnalyticsTestCard2
                    headerName="Speaking"
                    imgSrc={SpeakingImg}
                    color="#49D7F2"
                    QuestinsTotalTime={getTotalSpeakingTimeTaken()}
                    // QuestionNames={SpeakingQuestionNames}
                    QuestionNames={SpeakingQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    // QuestionTimes={getSpeakingTimeTaken()}
                    QuestionTimes={getSpeakingTimeTaken().filter((_, index) =>
                      SpeakingQuestionNames.some((name) =>
                        mockTestsResult.some(
                          (result) =>
                            result.UserResponse &&
                            result.UserResponse.SubCategory === name
                        )
                      )
                    )}
                  />
                  <AnalyticsTestCard2
                    headerName="Listening"
                    imgSrc={ListeningImg}
                    color="#868EAF"
                    QuestinsTotalTime={getTotalListeningTimeTaken()}
                    // QuestionNames={ListenQuestionNames}
                    QuestionNames={ListenQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    QuestionTimes={getListeningTimeTaken()}
                  />
                </FlexDiv>
              </>
            )}

            {mockTestType === "Listening" && (
              <>
                <FlexDiv
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: "1.25rem",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                <AnalyticsTestCard2
                    headerName="Listening"
                    imgSrc={ListeningImg}
                    color="#868EAF"
                    QuestinsTotalTime={getTotalListeningTimeTaken()}
                    // QuestionNames={ListenQuestionNames}
                    QuestionNames={ListenQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    QuestionTimes={getListeningTimeTaken()}
                  />
                  <AnalyticsTestCard2
                    headerName="Speaking"
                    imgSrc={SpeakingImg}
                    color="#49D7F2"
                    QuestinsTotalTime={getTotalSpeakingTimeTaken()}
                    // QuestionNames={SpeakingQuestionNames}
                    QuestionNames={SpeakingQuestionNames.filter((name) =>
                      mockTestsResult.some(
                        (result) =>
                          result.UserResponse &&
                          result.UserResponse.SubCategory === name
                      )
                    )}
                    // QuestionTimes={getSpeakingTimeTaken()}
                    QuestionTimes={getSpeakingTimeTaken().filter((_, index) =>
                      SpeakingQuestionNames.some((name) =>
                        mockTestsResult.some(
                          (result) =>
                            result.UserResponse &&
                            result.UserResponse.SubCategory === name
                        )
                      )
                    )}
                  />
                </FlexDiv>
              </>
            )}
        </FlexDiv>
        </WhiteDiv>
      </FlexDiv>
    </>
  ) : (
    <FlexDiv style={{ height: "100vh" }}>
      <LoadingModal />
    </FlexDiv>
  );
};

export default AnalyticsSectional;
