import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { FlexDiv } from "../../assets/styles/style";
import SeperatingHeader from "../Common/SeperatingHeader";
import { useMediaQuery } from "@mui/material";
import PayCardIcon from "../../assets/images/paycard-icon.svg";
import {
  DetailsListDiv,
  DetailsListTextCI,
  InputDiv,
  InputFieldSearch,
  PaymentCard,
  PaymentCardBtn,
  PaymentCardText,
  PaymentCardTitle,
  PurpleBtn,
  VIPSubDiv1,
  HelpBottomDiv,
  HelpBottomText,
  HelpBottomLink,
  RemoveBtn,
} from "./style";
import CheckCircleFilled from "../../assets/images/incl.svg";
import CancelCircleFilled from "../../assets/images/not-incl.svg";
import ReactAlfaPayment from "react-alfa-payment";
import "./style.css";
import SnackbarAlert from "../Login/SnackbarAlert";
import CircularProgress from "../Login/CircularLoader";
import { Base_URL } from "../../Client/apiURL";

//Original Keys for Original Payment
const MERCHANT_ID = "27294";
const STORE_ID = "038509";
const CHANNEL_ID = "1001";
const MERCHANT_HASH = "OUU362MB1uqZPfBfSn9r+lQfBfERSe3fHUfnKCVWWSG8qfo+mfv6ea3dXroHCB61";
const MERCHANT_USERNAME = "basowi";
const MERCHANT_PASSWORD = "kakMCs2KHaZvFzk4yqF7CA==";
const SECRET_KEY1 = "nj3GGZnQxnEWSc4y";
const SECRET_KEY2 = "8836802926650001";

function addDaysToUTC(days) {
  const now = new Date(); // Get current date
  now.setUTCDate(now.getUTCDate() + days); // Add the number of days
  return now.toISOString(); // Convert to UTC string
}

function updateUserData(updates) {
  // Retrieve the current userData from localStorage
  const userDataString = localStorage.getItem('userData');

  if (!userDataString) {
    return;
  }

  // Parse the string to an object
  const userData = JSON.parse(userDataString);

  // Update the specific fields
  const updatedUserData = {
    ...userData,
    ...updates, // Merge updates with existing userData
  };

  // Convert back to JSON string and save it in localStorage
  localStorage.setItem('userData', JSON.stringify(updatedUserData));
}

const CheckoutInitial = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan } = location.state || {};
  const isLaptop1100 = useMediaQuery("(max-width:1100px)");
  const isLaptop = useMediaQuery("(max-width:1250px)");
  const isMiniLaptop = useMediaQuery("(max-width:1000px)");
  const isTab = useMediaQuery("(max-width:750px)");
  const isMobile = useMediaQuery("(max-width:500px)");
  const [selected, setSelected] = useState(2);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentBtnLoading, setIsPaymentBtnLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("outlined");
  const [snackbarColor, setSnackbarColor] = useState("neutral");
  const [transactionRefNumber, setTransactionRefNumber] = useState("");
  const [finalTransactionRefNumber, setFinalTransactionRefNumber] =
    useState("");
  const [isTransactionDetailLoading, setIsTransactionDetailLoading] =
    useState(false);
  const [transactionDetails, setTransactionDetails] = useState({
    title: "",
    bank_name: "",
    acc_no: "",
    iban: "",
    whatsapp_link: "",
    support_email: "",
  });
  const [currentLocation, setCurrentLocation] = useState("");
  const [freeSubscription, setFreeSubscription] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("https://app.swiftuni.com/payment-success");

  useEffect(() => {
    if (typeof window !== "undefined") {
        setRedirectUrl(`${window.location.origin}/payment-success`);
    }
  }, []);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      setIsTransactionDetailLoading(true);
      try {
        const response = await axios.get(
          `${Base_URL}/app/admin/get-transaction-details`,
          { withCredentials: true }
        );
        const data = response.data;

        if (data?.response?.TransactionDetails) {
          const parsedDetails = JSON.parse(data.response.TransactionDetails);
          setTransactionDetails(parsedDetails);
        } else {
          console.error("Failed to fetch transaction details.");
        }
      } catch (error) {
        console.error("Error fetching transaction details:", error);
      } finally {
        setIsTransactionDetailLoading(false);
      }
    };

    fetchTransactionDetails();
  }, []);

  useEffect(() => {
    if (plan && plan.id) {
      localStorage.setItem("currentSubscriptionId", plan.id);
    }
  }, [plan]);

  useEffect(() => {
    let currentPlan = location.state?.plan;
    if (!currentPlan) {
      const storedPlan = sessionStorage.getItem("plan");
      currentPlan = storedPlan ? JSON.parse(storedPlan) : null;
    }
    setSelected(currentPlan);
  }, [location.state]);

  const parsePrice = (priceStr) => {
    if (typeof priceStr === "string") {
      return parseInt(priceStr.replace(/[^\d]/g, ""), 10) || 0;
    }
    return 0;
  };

  let PlanPrice = 0;
  if (plan) {
    PlanPrice = parsePrice(plan.price);
  }

  const TotalFinalPrice = PlanPrice - discount;

  const handlePromoCodeSubmit = async () => {
    if (!promoCode) {
      setSnackbarMessage("Please enter promo code.");
      setSnackbarVariant("soft");
      setSnackbarColor("danger");
      setSnackbarOpen(true);
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${Base_URL}/app/users/promo-codes/get-promocode?promocode_id=${promoCode}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        response.data &&
        response.data.responseCode === 200 &&
        response.data.response
      ) {
        const { Percentage = null, FixedAmount = 0 } = response.data.response;
        let discountAmount = 0;
        if (Percentage !== null) {
          discountAmount = (PlanPrice * Percentage) / 100;
          if(Percentage === 100){
            setFreeSubscription(true);
          }
        } else if (FixedAmount !== 0) {
          discountAmount = FixedAmount;
        }
        setDiscount(discountAmount);
        setSnackbarMessage("Promo code applied successfully!");
        setSnackbarVariant("soft");
        setSnackbarColor("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Promo code is not valid!");
        setSnackbarVariant("soft");
        setSnackbarColor("danger");
        setSnackbarOpen(true);
        setDiscount(0);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching promo code:", error);
      setDiscount(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      const { promoCode: statePromoCode, discount: stateDiscount } =
        location.state;
      if (statePromoCode) setPromoCode(statePromoCode);
      if (stateDiscount) setDiscount(stateDiscount);
    }
  }, [location.state]);

  const handleRemovePromoCode = () => {
    setPromoCode("");
    setDiscount(0);
    setSnackbarMessage("Promo code removed.");
    setSnackbarVariant("soft");
    setSnackbarColor("info");
    setSnackbarOpen(true);
    setFreeSubscription(false);
  };

  const handleFreeSubscription = async () => {
    try {      
      const url = `${Base_URL}/app/users/user-transactions/free-subscription`;
      
      const payload = {
        subscription_id: plan.id,
        promocode_id: promoCode,
      };
      setIsPaymentBtnLoading(true);
      await axios.post(url, payload, {
        withCredentials: true,
      });
      setIsPaymentBtnLoading(false);
      updateUserData({
        SubscriptionEndTimeUTC: addDaysToUTC(plan.days? plan.days: 0),
        IsSubscribed: true,
        SubscriptionID: plan.id,
      });
      setSnackbarMessage("Subscribed Successfully");
      setSnackbarVariant("soft");
      setSnackbarColor("success");
      setSnackbarOpen(true);      
      setTimeout(() => {
        window.location.reload();
      }, 2000); 
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  const handlePayWithBankAcc = () => {
    sessionStorage.setItem("plan", JSON.stringify(plan));

    const stateData = {
      plan,
      discount,
      totalFinalPrice: TotalFinalPrice,
    };

    if (promoCode.trim() !== "" && discount > 0) {
      stateData.promoCode = promoCode;
    }

    navigate(`/checkout-pay-with-bank-account`, {
      state: stateData,
      replace: true,
    });
  };

  useEffect(() => {
    const generateTransactionRefNumber = () => {
      let result = "";
      for (let i = 0; i < 24; i++) {
        result += Math.floor(Math.random() * 10);
      }
      return result;
    };

    setTransactionRefNumber(generateTransactionRefNumber());
  }, [plan]);

  useEffect(() => {
    if (finalTransactionRefNumber) {
      const hiddenButton = document.querySelector(".credit-debit-card-btn");
      if (hiddenButton) {
        setIsPaymentBtnLoading(false);
        localStorage.setItem("isUserTransaction", true);
        hiddenButton.click();
      }
    }
  }, [finalTransactionRefNumber]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  }, []);

  const handlePromoCodeAndTriggerPayment = async () => {
    setIsPaymentBtnLoading(true);

    if (promoCode) {
      try {
        const response = await axios.get(
          `${Base_URL}/app/users/promo-codes/check-promocode?promocode_id=${promoCode}`,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (response.data.responseCode === 200) {
          // setFinalTransactionRefNumber(`${transactionRefNumber}-${promoCode}`);
          // setFinalTransactionRefNumber(`${transactionRefNumber}-${promoCode}_${currentLocation.latitude}_${currentLocation.longitude}`);
          setFinalTransactionRefNumber(`${transactionRefNumber}-${promoCode}-Pakistan`);
        } else if (response.data.responseCode === 300) {
          console.error("Promo code not valid:", response.data.message);
          // setFinalTransactionRefNumber(transactionRefNumber);
          setFinalTransactionRefNumber(`${transactionRefNumber}--Pakistan`);
        }
      } catch (error) {
        console.error("Error validating promo code:", error);
        setIsPaymentBtnLoading(false);
        return;
      }
    } else {
      // setFinalTransactionRefNumber(transactionRefNumber);
      setFinalTransactionRefNumber(`${transactionRefNumber}--Pakistan`);
    }
  };

  const padDiscount = (discount, planPrice) => {
    const discountStr = discount.toString();
    const priceStr = planPrice.toString();
    const zerosNeeded = priceStr.length - discountStr.length;

    const hiddenZeros = "0".repeat(zerosNeeded > 0 ? zerosNeeded : 0);

    return (
      <>
        <span style={{ visibility: "hidden" }}>{hiddenZeros}</span>
        {discountStr}
      </>
    );
  };

  return (
    <FlexDiv style={{ width: "100%" }}>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        color={snackbarColor}
      />
      <FlexDiv
        style={{
          flexDirection: "column",
          padding: isMiniLaptop ? "1.5rem 2% 2rem" : "6.5rem 3% 3.94rem",
          maxWidth: "75rem",
          width: "100%",
        }}
      >
        <SeperatingHeader title="Features:" />

        <FlexDiv
          style={{ flexDirection: "column", width: "100%" }}
        >
          <FlexDiv
            style={{
              flexDirection: isTab ? " column" : "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: isTab ? "center" : "flex-start",
              marginTop: "0.5rem",
              gap: "1.75rem",
            }}
          >
            <FlexDiv
              style={{
                width: "100%",
                flexDirection: "column",
                position: "relative",
              }}
            >
              {plan && plan.premiumFeatures && (
                <>
                  {isMiniLaptop && !isTab && (
                    <FlexDiv
                      style={{
                        flexDirection: isMiniLaptop ? "column" : "row",
                        justifyContent: "space-between",
                        width: "40%",
                        maxWidth: "33rem",
                        alignItems: "flex-start",
                        gap: isMiniLaptop ? "1rem" : "2rem",
                        alignSelf: "flex-start",
                      }}
                    >
                      <DetailsListDiv>
                        {plan.premiumFeatures
                          .slice(0, 4)
                          .map((detail, index) => (
                            <VIPSubDiv1 key={index}>
                              <img
                                src={
                                  detail.included
                                    ? CheckCircleFilled
                                    : CancelCircleFilled
                                }
                                alt=""
                              />
                              <DetailsListTextCI
                                isIncluded={detail.included}
                                isSelected={selected === plan.id}
                              >
                                {detail.text}
                              </DetailsListTextCI>
                            </VIPSubDiv1>
                          ))}
                      </DetailsListDiv>

                      <DetailsListDiv>
                        {plan.premiumFeatures.slice(4).map((detail, index) => (
                          <VIPSubDiv1 key={index}>
                            <img
                              src={
                                detail.included
                                  ? CheckCircleFilled
                                  : CancelCircleFilled
                              }
                              alt=""
                            />
                            <DetailsListTextCI
                              isIncluded={detail.included}
                              isSelected={selected === plan.id}
                            >
                              {detail.text}
                            </DetailsListTextCI>
                          </VIPSubDiv1>
                        ))}
                      </DetailsListDiv>
                    </FlexDiv>
                  )}

                  {!isMiniLaptop && !isTab && (
                    <FlexDiv
                      style={{
                        flexDirection: isMiniLaptop ? "column" : "row",
                        justifyContent: "space-between",
                        width: "40%",
                        maxWidth: "33rem",
                        alignItems: "flex-start",
                        gap: isMiniLaptop ? "1rem" : "2rem",
                        alignSelf: isTab ? "center" : "flex-start",
                      }}
                    >
                      <DetailsListDiv>
                        {plan.premiumFeatures
                          .slice(0, 4)
                          .map((detail, index) => (
                            <VIPSubDiv1 key={index}>
                              <img
                                src={
                                  detail.included
                                    ? CheckCircleFilled
                                    : CancelCircleFilled
                                }
                                alt=""
                              />
                              <DetailsListTextCI
                                isIncluded={detail.included}
                                isSelected={selected === plan.id}
                              >
                                {detail.text}
                              </DetailsListTextCI>
                            </VIPSubDiv1>
                          ))}
                      </DetailsListDiv>

                      <DetailsListDiv>
                        {plan.premiumFeatures.slice(4).map((detail, index) => (
                          <VIPSubDiv1 key={index}>
                            <img
                              src={
                                detail.included
                                  ? CheckCircleFilled
                                  : CancelCircleFilled
                              }
                              alt=""
                            />
                            <DetailsListTextCI
                              isIncluded={detail.included}
                              isSelected={selected === plan.id}
                            >
                              {detail.text}
                            </DetailsListTextCI>
                          </VIPSubDiv1>
                        ))}
                      </DetailsListDiv>
                    </FlexDiv>
                  )}

                  {isTab && (
                    <FlexDiv
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        maxWidth: "29rem",
                        alignItems: "flex-start",
                        gap: isMobile ? "1rem" : "2rem",
                        alignSelf: "center",
                      }}
                    >
                      <DetailsListDiv>
                        {plan.premiumFeatures
                          .slice(0, 4)
                          .map((detail, index) => (
                            <VIPSubDiv1 key={index}>
                              <img
                                src={
                                  detail.included
                                    ? CheckCircleFilled
                                    : CancelCircleFilled
                                }
                                alt=""
                              />
                              <DetailsListTextCI
                                isIncluded={detail.included}
                                isSelected={selected === plan.id}
                              >
                                {detail.text}
                              </DetailsListTextCI>
                            </VIPSubDiv1>
                          ))}
                      </DetailsListDiv>

                      <DetailsListDiv>
                        {plan.premiumFeatures.slice(4).map((detail, index) => (
                          <VIPSubDiv1 key={index}>
                            <img
                              src={
                                detail.included
                                  ? CheckCircleFilled
                                  : CancelCircleFilled
                              }
                              alt=""
                            />
                            <DetailsListTextCI
                              isIncluded={detail.included}
                              isSelected={selected === plan.id}
                            >
                              {detail.text}
                            </DetailsListTextCI>
                          </VIPSubDiv1>
                        ))}
                      </DetailsListDiv>
                    </FlexDiv>
                  )}
                </>
              )}

              {/* {!isMiniLaptop && (
              <FlexDiv
                style={{
                  width: "100%",
                  maxWidth: "75rem",
                  position: "absolute",
                  bottom: "-21.40rem",
                }}
              >
                <HelpBottomDiv>
                  <HelpBottomText>
                    If you are facing any issue, please let us know on{" "}
                  </HelpBottomText>
                 <HelpBottomLink href={`mailto:${transactionDetails.support_email}`}>
                    {transactionDetails.support_email}
                 </HelpBottomLink>
                </HelpBottomDiv>
              </FlexDiv>
            )} */}
            </FlexDiv>

            <FlexDiv>
              <PaymentCard>
                <img src={PayCardIcon} alt="" />
                <PaymentCardTitle>{plan.heading}</PaymentCardTitle>
                {/* <FlexDiv
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "1.5rem",
                }}
              >
                <PaymentCardText>Premium Access 10 Days</PaymentCardText>
                <PaymentCardText>{plan.premiumAccessPrice}</PaymentCardText>
              </FlexDiv> */}
                <FlexDiv
                  style={{
                    marginTop: "1rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <PaymentCardText>Subtotal</PaymentCardText>
                  <PaymentCardText>{plan.price}</PaymentCardText>
                </FlexDiv>
                {discount > 0 && (
                  <FlexDiv
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "1rem",
                      alignItems: "flex-start",
                    }}
                  >
                    <PaymentCardText style={{ color: "green" }}>
                      Discount
                    </PaymentCardText>
                    <PaymentCardText
                      style={{
                        color: "green",
                      }}
                    >
                      {/* PKR {discount} */}
                      PKR {padDiscount(discount, PlanPrice)}
                    </PaymentCardText>
                  </FlexDiv>
                )}
                <FlexDiv
                  style={{
                    marginTop: "1rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <PaymentCardText style={{ color: "#996CFE" }}>
                    Total
                  </PaymentCardText>
                  <PaymentCardText style={{ color: "#996CFE" }}>
                    {`PKR ${TotalFinalPrice}`}
                  </PaymentCardText>
                </FlexDiv>
                <FlexDiv
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <PaymentCardText style={{ marginTop: "1rem" }}>
                    Promo Code
                  </PaymentCardText>
                </FlexDiv>
                {/* <FlexDiv
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "0.37rem",
                  }}
                >
                  <InputDiv>
                    <InputFieldSearch
                      placeholder="Enter promo code"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                  </InputDiv>
                  <PurpleBtn
                    onClick={handlePromoCodeSubmit}
                    disabled={isLoading}
                  >
                    {" "}
                    {isLoading ? (
                      <>
                        <CircularProgress />
                      </>
                    ) : (
                      "Apply"
                    )}
                  </PurpleBtn>
                </FlexDiv> */}
                <FlexDiv
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "0.37rem",
                  }}
                >
                  <InputDiv>
                    <InputFieldSearch
                      placeholder="Enter promo code"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                  </InputDiv>
                  {discount > 0 ? (
                    <RemoveBtn
                      onClick={() => {
                        handleRemovePromoCode();
                      }}
                      disabled={isLoading}
                    >
                      Remove
                    </RemoveBtn>
                  ) : (
                    <PurpleBtn
                      onClick={handlePromoCodeSubmit}
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress /> : "Apply"}
                    </PurpleBtn>
                  )}                  
                </FlexDiv>
                {freeSubscription?
                  <PaymentCardBtn
                    style={{ marginTop: "1.5rem"}}
                    onClick={handleFreeSubscription}
                    disabled={isPaymentBtnLoading}
                  >
                    {isPaymentBtnLoading ? (
                      <>
                        <CircularProgress />
                      </>
                    ) : (
                      "Activate Subscription"
                    )}
                  </PaymentCardBtn>
                :
                <>
                  <PaymentCardBtn
                    style={{ marginTop: "1.5rem", marginBottom: "-4rem" }}
                    onClick={handlePromoCodeAndTriggerPayment}
                    disabled={isPaymentBtnLoading}
                  >
                    {isPaymentBtnLoading ? (
                      <>
                        <CircularProgress />
                      </>
                    ) : (
                      "  Credit / Debit Card"
                    )}
                  </PaymentCardBtn>
                  <ReactAlfaPayment
                    alfaConfig={{
                      merchantId: MERCHANT_ID,
                      storeId: STORE_ID,
                      channelId: CHANNEL_ID,
                      merchantHash: MERCHANT_HASH,
                      merchantUsername: MERCHANT_USERNAME,
                      merchantPassword: MERCHANT_PASSWORD,
                      redirectUrl: redirectUrl,
                      secretKey1: SECRET_KEY1,
                      secretKey2: SECRET_KEY2,
                      transactionReferenceNumber: finalTransactionRefNumber,
                      transactionAmount: TotalFinalPrice,
                    }}
                    message=" Credit / Debit Card"
                    className="credit-debit-card-btn"
                  />
                </>
                }
              </PaymentCard>              

            </FlexDiv>
          </FlexDiv>
          {!isMiniLaptop && (
            <FlexDiv
              style={{
                width: isLaptop ? (isLaptop1100 ? "52%" : "56%") : "60%",
                alignSelf: "flex-start",
                marginTop: isLaptop1100 ? "-5.4rem " : "-3.9rem",
              }}
            >
              <HelpBottomDiv>
                <HelpBottomText>
                  If you are facing any issue, please let us know on{" "}
                </HelpBottomText>
                <HelpBottomLink
                  href={`mailto:${transactionDetails.support_email}`}
                >
                  <>
                    {isTransactionDetailLoading ? (
                      <CircularProgress
                        color={"var(--Brand-Purple, #996cfe)"}
                      />
                    ) : (
                      <> {transactionDetails.support_email}</>
                    )}
                  </>
                </HelpBottomLink>
              </HelpBottomDiv>
            </FlexDiv>
          )}
        </FlexDiv>

        {isMiniLaptop && (
          <FlexDiv
            style={{ width: "100%", maxWidth: "75rem", marginTop: "1.7rem" }}
          >
            <HelpBottomDiv>
              <HelpBottomText>
                If you are facing any issue, please let us know on{" "}
              </HelpBottomText>
              <HelpBottomLink
                href={`mailto:${transactionDetails.support_email}`}
              >
                <>
                  {isTransactionDetailLoading ? (
                    <CircularProgress color={"var(--Brand-Purple, #996cfe)"} />
                  ) : (
                    <> {transactionDetails.support_email}</>
                  )}
                </>
              </HelpBottomLink>
            </HelpBottomDiv>
          </FlexDiv>
        )}
      </FlexDiv>
    </FlexDiv>
  );
};

export default CheckoutInitial;
