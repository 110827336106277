import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { FlexDiv } from "../../assets/styles/style";
import {
  ViewScoreHeader,
  Avatar,
  ViewScoreHeaderDiv,
  OverallScoreDisplay,
  OverallScoreText,
  OverallScoreDigit,
  ViewScoreHeaderText,
  ViewScoreTitle,
  ShareBtn,
  ShareBtnDiv,
  ViewScoreSubTitle,
  VSWhiteDiv,
  CircularProgDiv,
  SkillsBreakdownDiv,
  TestTypeText,
  TestTypeDiv,
  ViewScoreHeaderTest,
  CircularDiv,
  SkillsContainer,
  EndText,
  VerticalOverall,
  VerticalOverallDiv,
  VerticalOverallText,
  TestTypesDiv,
  TestImgAndTextDiv,
  Testext,
  TestScoresDiv,
  TestTypeImgs,
} from "./style";

import CircularScoreProgress from "../Writing/CircularScoreProgress";
import { ContentWrapper1, CircularProgText } from "../Writing/style";
import {
  CircularScoreProgressData1,
  CircularScoreProgressData2,
  skillsData,
  EnablingSkillsData, 
  testData,
} from "./data";
import LinearProgress from "./LinearProgress";
import SpeakingSvg from "../../assets/images/speakingsvg.svg";
import WritingSvg from "../../assets/images/writingsvg.svg";
import ReadingSvg from "../../assets/images/readingsvg.svg";
import ListeningSvg from "../../assets/images/listeningsvg.svg";
import ReadAloudSvg from "../../assets/images/readaloudsvg.svg";
import AI_Score from "../../assets/images/aiscoresvg.svg";
import EndTestScoreCard from "./EndTestScoreCard";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LoadingModal from "../Common/LoadingModal";
import AnalyticsForMtScore from "./AnalyticsForMtScore";
import { Base_URL } from "../../Client/apiURL";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchMockTestScore } from "./helperFunctions";

const formatDate = (isoString) => {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();


  return `${day}/${month}/${year}`;
};

const categoryOrder = ["Speaking", "Writing", "Reading", "Listening"];
const subCategoryOrder = {
  Speaking: ["Read Aloud", "Repeat Sentence", "Describe Image", "Re-tell Lecture", "Respond to a situation", "Answer Short Question"],
  Writing: ["Summarize Written Text", "Write Essay", "Write Email"],
  Reading: ["Reading & Writing: Fill in the Blanks", "Multiple Choice, Multiple Answers", "Re-order Paragraphs", "Reading: Fill in the Blanks", "Multiple Choice, Single Answer"],
  Listening: ["Summarize Spoken Text", "Listening: Multiple Choice, Multiple Answers", "Fill in the Blanks", "Highlight Correct Summary", "Listening: Multiple Choice, Single Answer", "Select Missing Word", "Highlight Incorrect Words", "Write from Dictation"],
};


  // Helper function to sort responses
  const sortResponses = (responses, categoryOrder, subCategoryOrder) => {
    return responses.sort((a, b) => {
      // Handle missing Category or SubCategory
      const categoryA = a.Category || "";
      const categoryB = b.Category || "";
      const subCategoryA = a.SubCategory || "";
      const subCategoryB = b.SubCategory || "";
  
      // Determine the order index for categories
      const categoryIndexA = categoryOrder.indexOf(categoryA);
      const categoryIndexB = categoryOrder.indexOf(categoryB);
  
      // Categories not in categoryOrder are placed at the end
      const finalCategoryIndexA = categoryIndexA !== -1 ? categoryIndexA : categoryOrder.length;
      const finalCategoryIndexB = categoryIndexB !== -1 ? categoryIndexB : categoryOrder.length;
  
      if (finalCategoryIndexA < finalCategoryIndexB) return -1;
      if (finalCategoryIndexA > finalCategoryIndexB) return 1;
  
      // If categories are the same, sort by subcategory
      if (categoryA === categoryB) {
        const subOrder = subCategoryOrder[categoryA] || [];
        const subIndexA = subOrder.indexOf(subCategoryA);
        const subIndexB = subOrder.indexOf(subCategoryB);
  
        const finalSubIndexA = subIndexA !== -1 ? subIndexA : subOrder.length;
        const finalSubIndexB = subIndexB !== -1 ? subIndexB : subOrder.length;
  
        if (finalSubIndexA < finalSubIndexB) return -1;
        if (finalSubIndexA > finalSubIndexB) return 1;
      }
  
      return 0;
    });
  };
  

const ViewScoreForTeacher = () => {
  const { userId, mockTestAttemptedId, typeOfTest } = useParams();
  const isTab = useMediaQuery("(max-width:1000px)");
  const isSTab = useMediaQuery("(max-width: 700px)");
  const circularData1 = CircularScoreProgressData1[0].CircularScoreProgress;
  const circularData2 = CircularScoreProgressData2[0].CircularScoreProgress;
  const [selectedTest, setSelectedTest] = useState("speaking");
  const [mockTestsResult, setMockTestsResult] = useState([]);
  const [listeningTests, setListeningTests] = useState([]);
  const [readingTests, setReadingTests] = useState([]);
  const [writingTests, setWritingTests] = useState([]);
  const [speakingTests, setSpeakingTests] = useState([]);
  const [enableSkillTests, setEnableSkillTests] = useState([]);
  const [testCounts, setTestCounts] = useState(null);
  const [speakingScore, setSpeakingScore] = useState(0);
  const [writingsScore, setWritingScore] = useState(0);
  const [readingsScore, setReadingScore] = useState(0);
  const [listeningsScore, setListeningScore] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [mockTestType, setMockTestType] = useState("");

  const url = `${Base_URL}/app/admin/mock-tests/get-score?user_id=${userId}&mock_test_attempted_id=${mockTestAttemptedId}`;
  const withCredentials = false;
  
  useEffect(() => {
    const fetchAndHandleScoreData = async () => {
      const result = await fetchMockTestScore({
        url,
        setIsLoading,
        setMockTestsResult,
        splitCategories,
        setMockTestType,
        withCredentials,
      });

      if (result.success) {
        // toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    };
    
    fetchAndHandleScoreData();
  }, []);

  const handleTestClick = (type) => {
    if (selectedTest !== type) {
      setSelectedTest(type);
    }
  };

  const splitCategories = (data) => {
    const categorizedData = data.reduce(
      (acc, curr) => {
        const subCategory = curr.UserResponse?.SubCategory;
        if (subCategory) {
          switch (subCategory) {
          case 'Write Essay':
            acc.writing.push(curr);
            acc.enableSkills.push(curr)
            acc.we.push(curr);
            break;
          case 'Summarize Written Text':
            acc.writing.push(curr);
            acc.reading.push(curr);
            acc.enableSkills.push(curr)
            acc.swt.push(curr);
            break;
          case 'Reading & Writing: Fill in the Blanks':
            acc.writing.push(curr);
            acc.reading.push(curr);
            acc.rwfib.push(curr);
            break;
          case 'Summarize Spoken Text':
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.enableSkills.push(curr)
            acc.sst.push(curr);
            break;
          case 'Fill in the Blanks':
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.lfib.push(curr);
            break;
          case 'Write from Dictation':
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.wfd.push(curr);
            // acc.enableSkills.push(curr)
            break;
          case 'Read Aloud':
            acc.ra.push(curr);
            acc.reading.push(curr);
            acc.speaking.push(curr);
            acc.enableSkills.push(curr)
            break;                        
          case 'Multiple Choice, Multiple Answers':
            acc.mcma.push(curr);
            acc.reading.push(curr);
            break;
          case 'Re-order Paragraphs':
            acc.reading.push(curr);
            acc.rop.push(curr);
            break;
          case 'Multiple Choice, Single Answer':
            acc.reading.push(curr);
            acc.mcsa.push(curr);
            break;
          case 'Highlight Correct Summary':
            acc.reading.push(curr);
            acc.listening.push(curr);
            acc.hcs.push(curr);
            break;                                                            
          case 'Highlight Incorrect Words':
            acc.reading.push(curr);
            acc.listening.push(curr);
            acc.hiw.push(curr);
            break;
          case 'Reading: Fill in the Blanks':
            acc.reading.push(curr);
            acc.rfib.push(curr);
            break;
          case 'Repeat Sentence':
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.rs.push(curr);
            break;
          case 'Describe Image':
            acc.speaking.push(curr);
            acc.di.push(curr);
            break;
          case 'Re-tell Lecture':
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.rl.push(curr);
            break;
          case 'Answer Short Question':
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.asq.push(curr);
            break;
          default:
            acc.listening.push(curr);
            switch (curr.UserResponse.SubCategory){
              case 'Listening: Multiple Choice, Multiple Answers':
                acc.lmcma.push(curr);
                break;
              case 'Listening: Multiple Choice, Single Answer':
                acc.lmcsa.push(curr);
                break;
              default:
                acc.smw.push(curr);
                break;
            }
            break;
        } }
        else {
          acc.noScoreAvailable.push(curr);
        }
        return acc;
      },
      { listening: [], reading: [], writing: [], speaking: [], enableSkills: [], 
        ra: [], rs: [], di: [], rl: [], asq: [],
        swt: [], we: [],
        rwfib: [], rfib: [], mcma: [], mcsa: [], rop: [],
        sst: [], lmcma: [], lmcsa: [], hiw: [], lfib: [], wfd: [], hcs: [], smw: [], noScoreAvailable: [],
      }
    );
    
    setTestCounts([
      categorizedData.ra, categorizedData.rs, categorizedData.di, categorizedData.rl, categorizedData.asq,
      categorizedData.swt, categorizedData.we,
      categorizedData.rwfib, categorizedData.rfib, categorizedData.mcma, categorizedData.mcsa, categorizedData.rop,
      categorizedData.sst, categorizedData.lmcma, categorizedData.lmcsa, categorizedData.hiw, categorizedData.lfib, categorizedData.wfd, categorizedData.hcs, categorizedData.smw      
    ])
    setEnableSkillTests(categorizedData.enableSkills);
    setListeningTests(categorizedData.listening);
    setReadingTests(categorizedData.reading);
    setWritingTests(categorizedData.writing);
    setSpeakingTests(categorizedData.speaking);    
  }

  const returnScoringFunction = (type) => {
    if (type === "Listening"){
      return listeningsScore?listeningsScore>10?listeningsScore:10:10;
    }else if(type === "Reading"){
      return readingsScore?readingsScore>10?readingsScore:10:10;
    }else if(type === "Writing"){
      return writingsScore?writingsScore>10?writingsScore:10:10;
    }else{
      return speakingScore?speakingScore>10?speakingScore:10:10;
    }
  }

  const sendTest = (type) => {    
    if (type === "listening"){
      return listeningTests;
    }else if(type === "reading"){
      return readingTests;
    }else if(type === "writing"){
      return writingTests;
    }else{
      return speakingTests;
    }
  }

  const getOverallScore = () => {
    let overall = (speakingScore + writingsScore + readingsScore + listeningsScore) / 4;
    if (overall > 12) {
      overall -= overall > 40 ? 2 : 1;
    }
    return overall > 10 ? parseInt(overall) : 10;
  };
  


  return (
  mockTestsResult && mockTestsResult[0]?.MockTestName ?
    <>
      <FlexDiv
        style={{
          flexDirection: "column",
          // padding: isTab ? "1.5rem 2% 2rem" : "6.5rem 3% 2rem",

          padding: isTab ? "0.5rem 1% 2rem" : "2rem 2% 2rem",
        }}
      >
        <ViewScoreHeader>
          <ViewScoreHeaderDiv>
            <Avatar src="" alt="" />
            <div>
              <ViewScoreTitle>
              SCORE REPORT
              </ViewScoreTitle>
              <ViewScoreHeaderTest>{mockTestsResult && mockTestsResult[0]?.MockTestName ? mockTestsResult[0]?.MockTestName :'Loading'}</ViewScoreHeaderTest>
              <ViewScoreHeaderText>{mockTestsResult && mockTestsResult[0]?.MockTestName ? formatDate(mockTestsResult[0]?.AttemptedAt) :'Loading'}</ViewScoreHeaderText>
              <ShareBtnDiv>
                <ShareBtn>Share</ShareBtn>
                {/* <ShareBtn onClick={() => navigate(`/mt-score-full-analytics/${id}`)}>Analytics</ShareBtn> */}
              </ShareBtnDiv>
            </div>
          </ViewScoreHeaderDiv>

          <OverallScoreDisplay>
            <OverallScoreText>Overall Score</OverallScoreText>
            <OverallScoreDigit>{getOverallScore()}</OverallScoreDigit>
          </OverallScoreDisplay>
        </ViewScoreHeader>
        <VSWhiteDiv>
          <FlexDiv
            style={{
              padding: "1.5rem 1.5rem",
              flexDirection: "column",
              gap: "1.5rem",
              width: "97%",
            }}
          >
            <ViewScoreSubTitle>Communication Skills</ViewScoreSubTitle>
            <CircularProgDiv>
              <CircularDiv>
                {circularData1.map((progress, index) => (
                  <ContentWrapper1 key={index}>
                    <CircularScoreProgress
                      score={progress.key === "Reading"? readingsScore?readingsScore:10 : listeningsScore?listeningsScore:10}
                      totalScore={90}
                      progressColorFilled={progress.progressColorFilled}
                      progressColorUnfilled={progress.progressColorUnfilled}
                      scoreColor={progress.scoreColor}
                      circleSize={100}
                      fontSize={"1.5rem"}
                    />
                    <CircularProgText>{progress.Title}</CircularProgText>
                  </ContentWrapper1>
                ))}
              </CircularDiv>
              <CircularDiv>
                {circularData2.map((progress, index) => (
                  <ContentWrapper1 key={index}>
                    <CircularScoreProgress
                      score={progress.key === "Writing"? writingsScore?writingsScore:10 :speakingScore?speakingScore:10}
                      totalScore={90}
                      progressColorFilled={progress.progressColorFilled}
                      progressColorUnfilled={progress.progressColorUnfilled}
                      scoreColor={progress.scoreColor}
                      circleSize={100}
                      fontSize={"1.5rem"}
                    />
                    <CircularProgText>{progress.Title}</CircularProgText>
                  </ContentWrapper1>
                ))}
              </CircularDiv>
            </CircularProgDiv>
            <ViewScoreSubTitle>Skills Breakdown</ViewScoreSubTitle>
            <SkillsBreakdownDiv>
              {skillsData.map((skill) => (
                <SkillsContainer key={skill.title}>
                  <TestTypeDiv>
                    <TestTypeText>{skill.title}</TestTypeText>
                    <TestTypeText>{returnScoringFunction(skill.title)}</TestTypeText>
                  </TestTypeDiv>
                  <LinearProgress
                    progressColor={skill.progressColor}
                    score={returnScoringFunction(skill.title) + 10}
                  />
                </SkillsContainer>
              ))}

              {/* <ViewScoreSubTitle
                style={{ marginLeft: "1.88rem", padding: "1.5rem 0rem 0rem" }}
              >
                Enabling Skills
              </ViewScoreSubTitle>

              <VerticalOverallDiv>
                <VerticalOverallText>44 Overall</VerticalOverallText>
                <VerticalOverall />
              </VerticalOverallDiv> 

              {EnablingSkillsData.map((skill) => (
                <SkillsContainer key={skill.title}>
                  <TestTypeDiv>
                    <TestTypeText>{skill.title}</TestTypeText>
                    <TestTypeText>{getEnableSkillsScore(skill.skill)}</TestTypeText>
                  </TestTypeDiv>                  
                  <LinearProgress
                    progressColor={skill.progressColor}
                    score={getEnableSkillsScore(skill.skill)}
                  />
                </SkillsContainer>
              ))} */}

              <EndText>
                This scorecard has been generated solely on the basis of your
                performance in the above-mentioned mock test provided
                by SwiftUni. Please note that this score report cannot be used
                for applying to any university or for migration purposes.
              </EndText>
            </SkillsBreakdownDiv>

            <div style={{ width: "100%" }}>
              <TestTypesDiv>
                {["speaking", "writing", "reading", "listening"].map(
                  (test, index) => (
                    <TestImgAndTextDiv
                      key={index}
                      borderColor={
                        {
                          speaking: "#49D7F2",
                          writing: "#FF5D5D",
                          reading: "#AD826E",
                          listening: "#868EAF",
                        }[test]
                      }
                      isSelected={selectedTest === test}
                      onClick={() => handleTestClick(test)}
                      ZJIDFO
                    >
                      <TestTypeImgs
                        src={
                          {
                            speaking: SpeakingSvg,
                            writing: WritingSvg,
                            reading: ReadingSvg,
                            listening: ListeningSvg,
                          }[test]
                        }
                        alt={test.toUpperCase()}
                      />
                      {(!isSTab || selectedTest === test) && (
                        <Testext
                          textColor={
                            {
                              speaking: "#49D7F2",
                              writing: "#FF5D5D",
                              reading: "#AD826E",
                              listening: "#868EAF",
                            }[test]
                          }
                        >
                          {test.toUpperCase()}
                        </Testext>
                      )}
                    </TestImgAndTextDiv>
                  )
                )}
              </TestTypesDiv> 

              <TestScoresDiv>
                {selectedTest &&
                  testData[selectedTest].map((data, index) => (
                    <EndTestScoreCard key={index} data={data} tests={sendTest(selectedTest)} type={selectedTest}/>
                  ))}
              </TestScoresDiv>
            </div>
          </FlexDiv>
        </VSWhiteDiv>
      </FlexDiv>
      <AnalyticsForMtScore counts={testCounts} speakingTotScore={setSpeakingScore} writingTotScore={setWritingScore} readingTotScore={setReadingScore} listeningTotScore={setListeningScore} seen={false}/>
    </>
    :
    <>
    <FlexDiv style={{height:'100vh'}}>
      {isLoading ? ( <LoadingModal />) : (              
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            color: "#999",
            padding: "20px",
            fontWeight: "500",
          }}
        >
          Mock Test Score Not Found in Record.
        </div>)} 
    </FlexDiv>    
    </>
  );
};

export default ViewScoreForTeacher;
