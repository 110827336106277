// import { useEffect } from "react";

// const useSetFavicon = () => {
//   useEffect(() => {
//     const storedData = JSON.parse(localStorage.getItem("portalData"));
//     const faviconURL = storedData?.portal_favicon;

//     if (!faviconURL) return;

//     let faviconLink = document.querySelector("link[rel*='icon']");
//     if (!faviconLink) {
//       faviconLink = document.createElement("link");
//       faviconLink.rel = "icon";
//       faviconLink.type = "image/x-icon";
//       document.head.appendChild(faviconLink);
//     }

//     faviconLink.href = faviconURL;
    
//     console.log("Favicon updated to:", faviconURL);

//   }, []);
// };

// export default useSetFavicon;

import { useEffect } from 'react';

const useSetFavicon = () => {
  const storedData = JSON.parse(localStorage.getItem("portalData"));
  
  useEffect(() => {
    if (storedData?.portal_favicon) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = storedData?.portal_favicon;
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    if (storedData?.portal_name) {
      let portalName = storedData?.portal_name.toLowerCase() === 'app' ? 'Swiftuni' : storedData?.portal_name;
      portalName = portalName.charAt(0).toUpperCase() + portalName.slice(1);
      document.title = portalName;
    }
  }, [storedData]);
};

export default useSetFavicon;
