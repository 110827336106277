import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  SideCardContainer,
  PlanInfoHeading,
  CancelSubDiv,
  CancelSubText,
  VIPDiv,
  VIPSubDiv1,
  VIPText,
  ActiveDiv,
  ActiveText,
  StartTestBtn,
  AllPlansTextDiv,
  AllPlansText,
  TabsTextDiv,
  PlanDetailsDiv,
  PlanDetailsHeading,
  PlanDetailsDesc,
  PriceDiv,
  PriceText,
  ActualPriceText,
  PlanNameDiv as UpgradeBtnDiv,
  DetailsListDiv as PremiumFeaturesDiv,
  DetailsListText as PremiumFeaturesListText,
  PurchasedText,
  VIPTextDiv,
  PlanDetailsOuterDiv,
  StyledDiscountedStar,
} from "./style";
import { FlexDiv } from "../../assets/styles/style";
import CheckCircle from "../../assets/images/check_circle.svg";
import ActiveDot from "../../assets/images/active-dot.svg";
import CheckCircleFilled from "../../assets/images/check-circle-filled.svg";
import CancelCircleFilled from "../../assets/images/cancel-circle-filled.svg";
// import { SubscriptionsData } from "./data";
import ProgressBar from "./ProgressBar";
import Modal from "react-modal";
import CancelSubscriptionPopup from "./CancelSubscriptionPopup";
import LoadingModal from "../Common/LoadingModal";
// const CryptoJS = require("crypto-js");
import DiscountStar from "./DiscountStar";
import { Base_URL } from "../../Client/apiURL";
import {
  NavDiamondImg,
  UpgradeDiv,
  UpgradeDivBoldText,
  UpgradeDivContactUs,
  UpgradeDivSimpleText,
} from "../Navbar/style";

const modalStyle = {
  overlay: {
    zIndex: 1002,
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(5px)",
    background: "none",
  },
  content: {
    border: "none",
    background: "transparent",
    inset: "0px",
    padding: "20px 1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const premiumFeatureslist3 = [
  { text: "AI Scoring", included: true },
  { text: "Prediction Files", included: true },
  { text: "Templates", included: true },
  { text: "Strategy Videos", included: true },
  { text: "Ai Study-Plan", included: true },
  { text: "Full Mock Tests", included: true },
  { text: "Sectional Mock Tests", included: true },
];

const PlanInfo = () => {
  const [allOrMock, setAllOrMock] = useState("all");
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [subscriptions, setSubscriptions] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [remianingDays, setRemianingDays] = useState(0);
  const [subDays, setSubDays] = useState(0);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [selected, setSelected] = useState(userData.SubscriptionId);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${Base_URL}/app/users/subscriptions/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        const data = await response.json();

        if (data.responseCode === 200) {
          const mappedData = data.response.map((item, index) => {
            const finalPrice =
              item.Discount > 0
                ? Math.round(item.PricePKR * (1 - item.Discount / 100))
                : item.PricePKR;
            if (
              userData.SubscriptionID &&
              item.Id === userData.SubscriptionID
            ) {
              const givenDateString = userData.SubscriptionEndTimeUTC;
              const givenDate = new Date(givenDateString);
              const currentDate = new Date();
              const timeDifference = givenDate - currentDate;
              const dayDifference = Math.ceil(
                timeDifference / (1000 * 60 * 60 * 24)
              );
              setSubDays(item.Days);
              if (item.Days > 0 && item.Days) {
                setRemianingDays((dayDifference / item.Days).toFixed(2));
              } else {
                setRemianingDays(0);
              }
            } else if (userData.PlanID) {
              const givenDateString = userData.SubscriptionEndTimeUTC;
              const givenDate = new Date(givenDateString);
              const currentDate = new Date();
              const timeDifference = givenDate - currentDate;
              const dayDifference = Math.ceil(
                timeDifference / (1000 * 60 * 60 * 24)
              );

              if (dayDifference > 0) {
                setRemianingDays(dayDifference);
              } else {
                setRemianingDays(0);
              }
            }
            return {
              id: item.Id,
              heading: item.Name,
              description: item.Description,
              price: `PKR ${finalPrice}`,
              actualPrice: item.Discount > 0 ? `PKR ${item.PricePKR}` : null,
              // premiumAccessPrice: "PKR 1000",
              Discount: item.Discount,
              days: item.Days,
              activeStatus: item.InActive,
              planName: "Purchase",
              // planName: index === 0 ? "Current Plan" : "Upgrade Now",
              premiumFeatures: premiumFeatureslist3,
            };
          });
          console.log(mappedData);
          setSubscriptions(mappedData);
        }
      } catch (error) {
        console.error("Failed to fetch subscriptions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptions();
    setSelected(userData.SubscriptionID);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("sure-to-cancel");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleCheckout = (plan) => {
    if (!userData.IsSubscribed) {
      navigate("/checkout", { state: { plan } });
    }
  };

  const extractDigits = (text) => {
    const match = text?.match(/\d+/); 
    return match ? match[0] : "";
  };

  return (
    <>
      {isLoading && <LoadingModal />}
      <Modal isOpen={open} style={modalStyle}>
        <CancelSubscriptionPopup
          close={() => {
            setOpen(false);
          }}
          setReason={setReason}
          onSubmit={() => {
            // submitCancelationRequest();
            console.log("Cancel Request Submit");
          }}
        />
      </Modal>
      <SideCardContainer>
        {/* <FlexDiv style={{ justifyContent: "space-between", gap: "10px" }}>
          {" "}
          <PlanInfoHeading>Current subscription</PlanInfoHeading>
          <CancelSubDiv>
            <CancelSubText
              onClick={() => {
                setOpen(true);
              }}
            >
              Cancel my subscription
            </CancelSubText>
          </CancelSubDiv>
        </FlexDiv> */}
        <VIPDiv >
          <VIPSubDiv1>
            <img src={CheckCircle} alt="" />
            {userData?.IsSubscribed && userData?.PlanID !== null ? (
              // Case 1: User is subscribed and has a PlanID
              <VIPTextDiv>
                <VIPText>VIP {extractDigits(userData?.PlanName)} Days Subscription</VIPText>
                <ProgressBar value={Math.min(remianingDays, 100)} />
                {remianingDays > 0 && (
                  <VIPText>{remianingDays} Day(s) left</VIPText>
                )}
              </VIPTextDiv>
            ) : userData?.IsSubscribed && userData?.SubscriptionID !== null ? (
              // Case 2: User is subscribed and has a SubscriptionID but no PlanID
              <VIPTextDiv>
                <VIPText>
                  VIP {userData.IsSubscribed ? subDays : "0"} Days Subscription
                </VIPText>
                <ProgressBar value={remianingDays * 100} />
                {remianingDays > 0 && (
                  <VIPText>
                    {(subDays * remianingDays).toFixed(0)} Day(s) left
                  </VIPText>
                )}
              </VIPTextDiv>
            ) : (
              // Case 3: User is neither subscribed nor has a SubscriptionID
              <VIPTextDiv>
                <VIPText>No Active Subscription</VIPText>
                <ProgressBar value={remianingDays * 100} />
              </VIPTextDiv>
            )}
          </VIPSubDiv1>

          <ActiveDiv>
            <ActiveText>{remianingDays > 0 ? "Active" : "Inactive"}</ActiveText>
            <img src={ActiveDot} alt="" />
          </ActiveDiv>
        </VIPDiv>

        {/*   <VIPDiv>
          <VIPSubDiv1>
            <img src={CheckCircle} alt="" />
            <div>
              <VIPText>Mock Test - 100</VIPText>
              <PurchasedText>Purchased</PurchasedText>
            </div>
          </VIPSubDiv1>
          <StartTestBtn>Start Test</StartTestBtn>
        </VIPDiv> */}

        {/* (userData?.IsSubscribed && userData?.SubscriptionID && userData?.PlanID === null) ||
        (!userData?.IsSubscribed && userData?.SubscriptionID === null && userData?.PlanID === null)  */}
        

        {userData?.PortalId === 1 ? (
          <>
            {userData?.IsSubscribed && userData?.PlanID !== null ? (
              <FlexDiv
                style={{
                  justifySelf: "center",
                  alignSelf: "center",
                  marginTop: "0.55rem",
                  padding: "20px",
                  backgroundColor: "#f9f8fc",
                  borderRadius: "8px",
                  width: "96%",
                  height: "60vh",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    justifySelf: "center",
                    alignSelf: "center",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  <>
                    <div style={{ marginBottom: "10px" }}>
                      <UpgradeDivBoldText
                        style={{
                          fontSize: "14px",
                          color: "#666",
                          alignItems: "center",
                          justifySelf: "center",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      >
                        Your subscription has been activated.
                      </UpgradeDivBoldText>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#666",
                          alignItems: "center",
                          justifySelf: "center",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      >
                        You have full access to all features. Thank you for
                        being a valued member.
                      </div>
                    </div>
                    <UpgradeDivContactUs
                      onClick={() => window.open("/contact-us", "_blank")}
                      style={{
                        width: "40%",
                        borderRadius: "4px",
                        marginTop: "10px",
                        alignItems: "center",
                        justifySelf: "center",
                        alignSelf: "center",
                        textAlign: "center",
                      }}
                    >
                      Contact Support
                    </UpgradeDivContactUs>
                  </>
                </div>
              </FlexDiv>
            ) : (
              <div>
                <>
                  <div style={{ position: "relative" }}>
                    <TabsTextDiv>
                      <AllPlansTextDiv
                        isSelected={allOrMock === "all"}
                        onClick={() => setAllOrMock("all")}
                      >
                        <AllPlansText>All Plans</AllPlansText>
                      </AllPlansTextDiv>
                      {/* <AllPlansTextDiv
                        isSelected={allOrMock === "mock"}
                        onClick={() => setAllOrMock("mock")}
                      >
                        <AllPlansText>Mock Test</AllPlansText>
                      </AllPlansTextDiv> */}
                    </TabsTextDiv>

                    <hr
                      style={{
                        height: "1px",
                        background: "#E8E8E8",
                        width: "100%",
                        position: "absolute",
                        top: "22px",
                      }}
                    ></hr>
                  </div>
                  <PlanDetailsOuterDiv style={{ marginTop: "25px" }}>
                    {subscriptions.map((plan, index) => (
                      <PlanDetailsDiv
                        key={plan.id}
                        isSelected={selected === plan.id}
                        style={{
                          marginTop: index >= 3 ? "20px" : "0px",
                          position: "relative",
                        }}
                      >
                        {plan.Discount > 0 && (
                          <DiscountStar discount={plan.Discount} />
                        )}
                        <div>
                          <PlanDetailsHeading isSelected={selected === plan.id}>
                            {plan.heading}
                          </PlanDetailsHeading>
                          <PlanDetailsDesc isSelected={selected === plan.id}>
                            {plan.description}
                          </PlanDetailsDesc>
                        </div>
                        {/* <FlexDiv
                style={{
                  flexDirection: "column",
                  // alignItems: "flex-start",
                  gap: "30px",
                  // position: "absolute",
                  // top: "8rem", 
                }}
              > */}
                        <PriceDiv>
                          <PriceText isSelected={selected === plan.id}>
                            {plan.price}
                          </PriceText>
                          <ActualPriceText isSelected={selected === plan.id}>
                            {plan.actualPrice}
                          </ActualPriceText>
                        </PriceDiv>
                        <UpgradeBtnDiv
                          isSelected={selected === plan.id}
                          onClick={() => handleCheckout(plan)}
                          // disabled={userData.IsSubscribed && selected === plan.id}
                          disabled={["Purchased", "Locked"].includes(
                            userData.IsSubscribed
                              ? selected === plan.id
                                ? "Purchased"
                                : "Locked"
                              : plan.planName
                          )}
                        >
                          {userData.IsSubscribed
                            ? selected === plan.id
                              ? "Purchased"
                              : "Locked"
                            : plan.planName}
                        </UpgradeBtnDiv>
                        <PremiumFeaturesDiv>
                          {plan.premiumFeatures.map((detail, index) => (
                            <VIPSubDiv1 key={index}>
                              <img
                                src={
                                  detail.included
                                    ? CheckCircleFilled
                                    : CancelCircleFilled
                                }
                                alt=""
                              />
                              <PremiumFeaturesListText
                                isIncluded={detail.included}
                                isSelected={selected === plan.id}
                              >
                                {detail.text}
                              </PremiumFeaturesListText>
                            </VIPSubDiv1>
                          ))}
                        </PremiumFeaturesDiv>
                        {/* </FlexDiv> */}
                      </PlanDetailsDiv>
                    ))}
                  </PlanDetailsOuterDiv>
                </>
              </div>
            )}
          </>
        ) : (
          <>
            <FlexDiv
              style={{
                justifySelf: "center",
                alignSelf: "center",
                marginTop: "0.55rem",
                padding: "20px",
                backgroundColor: "#f9f8fc",
                borderRadius: "8px",
                width: "96%",
                height: "60vh",
                
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  justifySelf: "center",
                  alignSelf: "center",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                {userData?.IsSubscribed && userData?.PlanID ? (
                  <>
                    <div style={{ marginBottom: "10px" }}>
                      <UpgradeDivBoldText
                        style={{
                          fontSize: "14px",
                          color: "#666",
                          alignItems: "center",
                          justifySelf: "center",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      >
                        Your subscription has been activated.
                      </UpgradeDivBoldText>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "white",
                          alignItems: "center",
                          justifySelf: "center",
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      >
                        You have full access to all features. Thank you for
                        being a valued member.
                      </div>
                    </div>
                    <UpgradeDivContactUs
                      onClick={() => window.open("/contact-us", "_blank")}
                      style={{
                        width: "40%",
                        borderRadius: "4px",
                        marginTop: "10px",
                        alignItems: "center",
                        justifySelf: "center",
                        alignSelf: "center",
                        textAlign: "center",
                        color: "white",

                      }}
                    >
                      Contact Support
                    </UpgradeDivContactUs>
                  </>
                ) : (
                  <div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#666",
                        alignItems: "center",
                        justifySelf: "center",
                        alignSelf: "center",
                        textAlign: "center",
                      }}
                    >
                      Upgrading your plan will provide you with full access to
                      premium features. Reach out to our support team for more
                      information.
                    </div>
                    <UpgradeDivContactUs
                      onClick={() => window.open("/contact-us", "_blank")}
                      style={{
                        width: "40%",
                        borderRadius: "4px",
                        marginTop: "10px",
                        alignItems: "center",
                        justifySelf: "center",
                        alignSelf: "center",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Please contact us to upgrade your subscription plan.
                    </UpgradeDivContactUs>
                  </div>
                )}
              </div>
            </FlexDiv>
          </>
        )}
      </SideCardContainer>
    </>
  );
};

export default PlanInfo;
